import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";

import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../providers/CustomTableFilter";
import { useLocation } from "react-router-dom";
import { GET } from "../../../providers/useAxiosQuery";

export default function PageReport() {
	const location = useLocation();

	const [toggleModalFormReport, setToggleModalFormReport] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		limit: 10,
		search: "",
		// sort_field: "created_at",
		// sort_order: "desc",
		// status: ["Active"],
		// status: "Active",
		// isTrash: 0,
		from: location.pathname,
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			// sort_field: "created_at",
			// sort_order: "desc",
			// status: ["Active"],
			from: location.pathname,
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const {
		data: dataDocumentRecipient,
		refetch: refetchSourceDocumentRecipient,
	} = GET(
		`api/document_recipients?${new URLSearchParams(tableFilter)}`,
		"document_recipients_list"
	);

	// console.log("dataDocumentRecipient: ", dataDocumentRecipient);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper">
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24}>
							<Flex gap={12} justify="space-between">
								<TableGlobalSearchAnimated
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>

								<Flex gap={12}>
									<TableShowingEntriesV2 />
									<TablePageSize
										tableFilter={tableFilter}
										setTableFilter={setTableFilter}
									/>
								</Flex>
							</Flex>
						</Col>

						<Col xs={24} sm={24} md={24}>
							<Table
								className="ant-table-default ant-table-striped"
								// dataSource={dataSourceDocument && dataSourceDocument.data.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky={{
									offsetHeader: 0,
								}}
								// rowSelection={{
								// 	selectedRowKeys,
								// 	onChange: (selectedRowKeys) => {
								// 		setSelectedRowKeys(selectedRowKeys);
								// 	},
								// }}
							>
								<Table.Column
									title="Action"
									key="action"
									dataIndex="action"
									align="center"
									width={100}
									render={(text, record) => {
										return (
											<>
												<Button
													type="link"
													className="color-1"
													onClick={() =>
														setToggleModalFormReport({
															open: true,
															data: record,
														})
													}
												>
													<FontAwesomeIcon icon={faPencil} />
												</Button>
											</>
										);
									}}
								/>

								<Table.Column
									title="Title"
									key="title"
									dataIndex="title"
									sorter={(a, b) => a.title.localeCompare(b.title)}
								/>

								<Table.Column
									title="Subject"
									key="subject"
									dataIndex="subject"
									sorter={(a, b) => a.title.localeCompare(b.title)}
								/>

								<Table.Column
									title="Email Body"
									key="email_body"
									dataIndex="email_body"
									sorter={(a, b) => a.title.localeCompare(b.title)}
								/>

								<Table.Column
									title="From"
									key="from"
									dataIndex="from"
									sorter={(a, b) => a.title.localeCompare(b.title)}
								/>

								<Table.Column
									title="Received By"
									key="received_by"
									dataIndex="received_by"
									sorter={(a, b) => a.title.localeCompare(b.title)}
								/>
							</Table>
						</Col>

						<Col xs={24} sm={24} md={24}>
							<div className="tbl-bottom-filter">
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									// setPaginationTotal={dataSourceDocument?.data.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper"
								/>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
