import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	Divider,
	Flex,
	notification,
	Row,
	Select,
	Tag,
	Typography,
} from "antd";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBoxArchive,
	faEnvelope,
	faEnvelopes,
	faInbox,
	faShare,
	faStar,
	faTag,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { GET, POST } from "../../../providers/useAxiosQuery";

import PageMailContent from "./PageMailContent";
import TableMail from "./TableMail";
import ModalFileForward from "./components/ModalFileForward";
import ModalFormCompose from "./components/ModalFormCompose";
import ModalFileReceived from "./components/ModalFileReceived";
import ModalFilePreview from "./components/ModalFilePreview";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageMail() {
	const location = useLocation();
	dayjs.extend(relativeTime);

	const [dataTreeUser, setDataTreeUser] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [expandedRows, setExpandedRows] = useState({});
	const [activeTab, setActiveTab] = useState("AllMail");
	const [showPageMailContent, setShowPageMailContent] = useState(false); // true show page mail content, false show table mail content
	const [selectedDocumentID, setSelectedDocumentID] = useState(null);

	const [toggleModalFormCompose, setToggleModalFormCompose] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFileReceived, setToggleModalFileReceived] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFileForward, setToggleModalFileForward] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFilePreview, setToggleModalFilePreview] = useState({
		open: false,
		data: null,
	});

	const toggleShowAll = (recordId) => {
		setExpandedRows((prev) => ({
			...prev,
			[recordId]: !prev[recordId],
		}));
	};

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 10,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		from: location.pathname,
		department_id: "",
		docu_type: "",
		activeTab: activeTab,
		isRead: 0,
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			from: location.pathname,
			department_id: "",
			docu_type: "",
			activeTab: activeTab,
			isRead: 0,
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, activeTab]);

	GET(
		`api/tree_data_users`,
		"tree_data_users_dropdown",
		(res) => {
			setDataTreeUser(res.data);
		},
		false
	);

	const { data: documentFilterTypes } = GET(
		`api/document_filter_type`,
		"document_filter_type"
	);

	const { data: dataDocument, refetch: refetchSourceDocument } = GET(
		`api/documents?${new URLSearchParams(tableFilter)}`,
		"documents_received_list"
	);

	useEffect(() => {
		refetchSourceDocument();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const {
		mutate: mutateDeleteRefDocument,
		isLoading: isLoadingDocument,
	} = POST(`api/document_archived`, "documents_received_list");

	const handleSelectedArchived = (value) => {
		const id = value ? value : selectedRowKeys;

		let data = {
			ids: id,
			activeTab: activeTab,
		};
		mutateDeleteRefDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Mail",
						description: res.message,
					});

					if (showPageMailContent === true) {
						setShowPageMailContent(false);
					}
				} else {
					notification.error({
						message: "Mail",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const {
		mutate: mutateMarkReadEmail,
		isLoading: isLoadingMarkReadEmail,
	} = POST(`api/document_read`, "documents_received_list");

	const handleSelectedMarkRead = (value) => {
		const id = value ? value : selectedRowKeys;

		let data = {
			ids: id,
			activeTab: activeTab,
		};

		mutateMarkReadEmail(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Mail",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Mail",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const {
		mutate: mutateMarkImportant,
		isLoading: isLoadingMarkImportant,
	} = POST(`api/document_important`, "documents_received_list");

	const handleSelectedMarkImportant = (value) => {
		const id = value ? value : selectedRowKeys;

		let data = {
			ids: id,
			activeTab: activeTab,
		};

		mutateMarkImportant(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Mail",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Mail",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const handleColumnClick = (id) => {
		setSelectedDocumentID(id);
		setShowPageMailContent(true);
	};

	const renderTabContent = () => {
		return (
			<TableMail
				location={location}
				tableFilter={tableFilter}
				setTableFilter={setTableFilter}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
				handleSelectedArchived={handleSelectedArchived}
				isLoadingDocument={isLoadingDocument}
				handleSelectedMarkRead={handleSelectedMarkRead}
				isLoadingMarkReadEmail={isLoadingMarkReadEmail}
				handleSelectedMarkImportant={handleSelectedMarkImportant}
				isLoadingMarkImportant={isLoadingMarkImportant}
				//
				refetchSourceDocument={refetchSourceDocument}
				onColumnClick={handleColumnClick}
				toggleShowAll={toggleShowAll}
				dataDocument={dataDocument}
				expandedRows={expandedRows}
				activeTab={activeTab}
				//
				ModalFilePreview={ModalFilePreview}
			/>
		);
	};

	return (
		<>
			<Flex gap={10} className="mailbox-content">
				<Col xs={24} sm={24} md={24} lg={4} xl={4}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Button
								className="w-90"
								type="primary"
								name="btn_add"
								onClick={() =>
									setToggleModalFormCompose({
										open: true,
										data: null,
									})
								}
							>
								COMPOSE MAIL
							</Button>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Flex vertical>
								<Typography.Text type="secondary" className="mt-0">
									<strong>FOLDERS</strong>
								</Typography.Text>

								<Flex vertical align="flex-start">
									<Button
										type="link"
										className="pl-0"
										icon={<FontAwesomeIcon icon={faEnvelopes} />}
										onClick={() => {
											setActiveTab("AllMail");
											setShowPageMailContent(false);
										}}
									>
										All Mail
									</Button>
									<Divider className="mt-5 mb-5" />

									<Button
										type="link"
										className="pl-0"
										icon={<FontAwesomeIcon icon={faStar} />}
										onClick={() => {
											setActiveTab("Important");
											setShowPageMailContent(false);
										}}
									>
										Important
									</Button>
									<Divider className="mt-5 mb-5" />

									<Button
										type="link"
										className="pl-0"
										icon={<FontAwesomeIcon icon={faInbox} />}
										onClick={() => {
											setActiveTab("Inbox");
											setShowPageMailContent(false);
										}}
									>
										Inbox
									</Button>
									<Divider className="mt-5 mb-5" />

									<Button
										type="link"
										className="pl-0"
										icon={<FontAwesomeIcon icon={faEnvelope} />}
										onClick={() => {
											setActiveTab("Sent");
											setShowPageMailContent(false);
										}}
									>
										Sent
									</Button>
									<Divider className="mt-5 mb-5" />

									<Button
										type="link"
										className="pl-0"
										icon={<FontAwesomeIcon icon={faShare} />}
										onClick={() => {
											setActiveTab("Forwarded");
											setShowPageMailContent(false);
										}}
									>
										Forwarded
									</Button>
									<Divider className="mt-5 mb-5" />

									<Button
										type="link"
										className="pl-0"
										icon={<FontAwesomeIcon icon={faBoxArchive} />}
										onClick={() => {
											setActiveTab("Archived");
											setShowPageMailContent(false);
										}}
									>
										Archived
									</Button>
									<Divider className="mt-5 mb-5" />
								</Flex>
							</Flex>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Flex gap={10} vertical>
								<Typography.Title level={5} className="mt-0">
									Filter
								</Typography.Title>

								<Select
									placeholder="Type"
									style={{ width: 250 }}
									allowClear
									onChange={(value) =>
										setTableFilter((ps) => ({
											...ps,
											docu_type: value ? value : "",
										}))
									}
									options={
										documentFilterTypes && documentFilterTypes.data
											? documentFilterTypes.data.map((item) => ({
													label: item.type,
													value: item.type,
											  }))
											: []
									}
								/>

								<Select
									placeholder="Department"
									style={{ width: 250 }}
									allowClear
									showSearch
									filterOption={(input, option) => {
										// console.log("option", option);
										return (
											option.label.toLowerCase().indexOf(input.toLowerCase()) >=
											0
										);
									}}
									onChange={(value) =>
										setTableFilter((ps) => ({
											...ps,
											department_id: value ? value : "",
										}))
									}
									options={
										dataTreeUser && dataTreeUser
											? dataTreeUser
													.filter((item) => item.category == "Department")
													.map((item) => ({
														label: item.title,
														value: item.id,
													}))
											: []
									}
								/>
							</Flex>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Flex gap={10} vertical>
								<Typography.Title level={5} className="mt-0">
									Labels
								</Typography.Title>

								<Flex align="flex-start">
									<Tag icon={<FontAwesomeIcon icon={faTag} />}>CSP</Tag>
									<Tag icon={<FontAwesomeIcon icon={faTag} />}>CJEP</Tag>
									<Tag icon={<FontAwesomeIcon icon={faTag} />}>ETP</Tag>
								</Flex>
							</Flex>
						</Col>
					</Row>
				</Col>

				<Col
					xs={24}
					sm={24}
					md={24}
					lg={20}
					xl={20}
					className="page-mail-content"
				>
					{showPageMailContent ? (
						<PageMailContent
							activeTab={activeTab}
							dataDocument={
								dataDocument && dataDocument.data && dataDocument.data.data
									? dataDocument.data && dataDocument.data.data
									: []
							}
							selectedDocumentID={selectedDocumentID}
							setToggleModalFileReceived={setToggleModalFileReceived}
							setToggleModalFileForward={setToggleModalFileForward}
							setToggleModalFilePreview={setToggleModalFilePreview}
							handleSelectedArchived={handleSelectedArchived}
							toggleModalFilePreview={toggleModalFilePreview}
						/>
					) : (
						renderTabContent()
					)}
				</Col>
			</Flex>

			<ModalFormCompose
				toggleModalFormCompose={toggleModalFormCompose}
				setToggleModalFormCompose={setToggleModalFormCompose}
				dataTreeUser={dataTreeUser}
			/>

			<ModalFileReceived
				toggleModalFileReceived={toggleModalFileReceived}
				setToggleModalFileReceived={setToggleModalFileReceived}
				dataTreeUser={dataTreeUser}
			/>

			<ModalFileForward
				toggleModalFileForward={toggleModalFileForward}
				setToggleModalFileForward={setToggleModalFileForward}
				dataTreeUser={dataTreeUser}
			/>

			<ModalFilePreview
				toggleModalFilePreview={toggleModalFilePreview}
				setToggleModalFilePreview={setToggleModalFilePreview}
			/>
		</>
	);
}
