import { useEffect, useState } from "react";
import {
	Modal,
	Button,
	Empty,
	Row,
	Col,
	Flex,
	Checkbox,
	notification,
	Form,
} from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { PDFDocument } from "pdf-lib";
import downloadjs from "downloadjs";

import { Container } from "./draggable_components/Container";
import { CustomDragLayer } from "./draggable_components/CustomDragLayer";
import moment from "moment";
import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";
import FloatTextArea from "../../../../providers/FloatTextArea";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ModalFilePreview(props) {
	const { toggleModalFilePreview, setToggleModalFilePreview } = props;

	console.log("toggleModalFilePreview: ", toggleModalFilePreview);

	const [numPages, setNumPages] = useState();
	const [pageNumber, setPageNumber] = useState(1);
	const [signatureX, setSignatureX] = useState(341);
	const [signatureY, setSignatureY] = useState(-87);
	const [pdfWidth, setPdfWidth] = useState(0);
	const [pdfHeight, setPdfHeight] = useState(0);
	const [attachSignature, setAttachSignature] = useState(false);

	const { mutate: mutateDocumentSignature } = POST(
		`api/document_signature`,
		"documents_received_list"
	);

	const onFinish = () => {
		let data = {
			id:
				toggleModalFilePreview.data && toggleModalFilePreview.data.id
					? toggleModalFilePreview.data.id
					: "",
			document_id: toggleModalFilePreview.data.attachmentable_id,
			signature_pos_x: signatureX,
			signature_pos_y: signatureY,
			page_number: pageNumber,
		};

		mutateDocumentSignature(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "PDF Signature",
						description: res.message,
					});
				} else {
					notification.error({
						message: "PDF Signature",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const { mutate: mutateAttachSignature } = POST(
		`api/document_attach_signature`,
		"documents_received_list"
	);

	const handleAttachSignature = (e) => {
		let data = {
			attachment_id: toggleModalFilePreview.data.attachmentable_id,
			attach_signature: e.target.checked ? 1 : 0,
		};

		setAttachSignature(e.target.checked);

		mutateAttachSignature(data, {
			onSuccess: (res) => {
				if (res.success) {
				} else {
					notification.error({
						message: "Attach Signature",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		if (toggleModalFilePreview.data && toggleModalFilePreview.data.pdf) {
			async function getPdfPropertyValue() {
				const url = toggleModalFilePreview.data.pdf;
				const existingPdfBytes = await fetch(url).then((res) =>
					res.arrayBuffer()
				);
				const pdfDoc = await PDFDocument.load(existingPdfBytes);
				const pages = pdfDoc.getPages();
				const firstPage = pages[0];
				const { width, height } = firstPage.getSize();
				setPdfHeight(height);
				setPdfWidth(width);
			}
			getPdfPropertyValue();
		}
	}, [toggleModalFilePreview]);

	const onDocumentLoadSuccess = (e) => {
		setNumPages(e.numPages);
	};

	const downloadPdf = async () => {
		try {
			let file_name = toggleModalFilePreview.data.file_name ?? "fsuuecom.pdf";

			let url = toggleModalFilePreview.data.pdf;
			const existingPdfBytes = await fetch(url).then((res) => {
				if (!res.ok) {
					throw new Error(`HTTP error! status: ${res.status}`);
				}
				return res.arrayBuffer();
			});
			const pdfDoc = await PDFDocument.load(existingPdfBytes);

			const signatureUrl = toggleModalFilePreview.data.signature;
			const signatureImageBytes = await fetch(signatureUrl).then((res) =>
				res.arrayBuffer()
			);

			const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
			const signatureImageDims = signatureImage.scale(0.25);

			const pages = pdfDoc.getPages();
			const firstPage = pages[0];
			const { width, height } = firstPage.getSize();

			let signXPos = signatureX;
			let signYPos = Math.round(250 + signatureY);

			firstPage.drawImage(signatureImage, {
				x: signXPos,
				y: signYPos,
				width: signatureImageDims.width,
				height: signatureImageDims.height,
			});

			let dateCreated = `Date Created`;
			let textMoment = `${moment().format("MM/DD/YYYY")}`;

			firstPage.drawText(dateCreated, {
				x: signXPos + 120,
				y: signYPos + 20,
				size: 8,
			});
			firstPage.drawText(textMoment, {
				x: signXPos + 120,
				y: signYPos + 10,
				size: 8,
			});

			const pdfBytes = await pdfDoc.save();
			downloadjs(pdfBytes, file_name, "application/pdf");
		} catch (error) {
			console.error("Failed to fetch the signature image:", error);
			notification.error({
				message: "Download Error",
				description: "Failed to download the PDF with the signature.",
			});
		}
	};

	const RenderSignature = () => {
		return toggleModalFilePreview.data.signature === "no signature" ? (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: 5,
				}}
			>
				<img
					src="/assets/images/no_signature.png"
					alt="no signature"
					style={{
						width: 120,
					}}
				/>
				<div
					style={{
						fontSize: 10,
						width: 60,
					}}
				>
					No Signature
				</div>
			</div>
		) : (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: 5,
				}}
			>
				<img
					src={toggleModalFilePreview.data.signature}
					alt="pdf"
					style={{
						width: 120,
					}}
				/>
				<div
					style={{
						fontSize: 10,
						width: 60,
					}}
				>
					Date Created <br />
					{moment().format("MM/DD/YYYY")}
				</div>
			</div>
		);
	};

	const handlePdfFileRender = () => {
		if (toggleModalFilePreview.data && toggleModalFilePreview.data.pdf) {
			// console.log("attachSignature: ", attachSignature);

			return (
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex justify="flex-end">
							<h3>
								{toggleModalFilePreview.data
									? toggleModalFilePreview.data.file_name
									: "Unknown"}
							</h3>
						</Flex>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex justify="flex-end">
							<Form.Item name="notes" width="250px">
								<FloatTextArea label="Notes" placeholder="Notes" />
							</Form.Item>
						</Flex>
					</Col>

					<DndProvider backend={HTML5Backend}>
						<Container
							height={pdfHeight}
							content={<RenderSignature />}
							setSignatureX={setSignatureX}
							setSignatureY={setSignatureY}
							signatureX={signatureX}
							signatureY={signatureY}
							attachSignature={attachSignature}
						>
							<Document
								file={toggleModalFilePreview.data.pdf}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Page pageNumber={pageNumber} renderTextLayer={false} />
							</Document>
						</Container>

						<CustomDragLayer />
					</DndProvider>

					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-10">
						<Flex justify="center">
							<Checkbox
								name="pdf_signature"
								onChange={handleAttachSignature}
								checked={attachSignature}
							>
								Attach Signature?
							</Checkbox>
						</Flex>
					</Col>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: 12,
						}}
					>
						<div className="action">
							<Button
								onClick={() => setPageNumber((ps) => (ps > 1 ? ps - 1 : 1))}
								className={pageNumber > 1 ? "btn-main-primary" : ""}
							>
								PREV
							</Button>
							<Button
								onClick={() =>
									setPageNumber((ps) => (ps < numPages ? ps + 1 : numPages))
								}
								className={numPages !== pageNumber ? "btn-main-primary" : ""}
							>
								NEXT
							</Button>
						</div>

						<div
							style={{
								width: "100%",
								textAlign: "center",
							}}
						>
							<Button
								onClick={() => downloadPdf()}
								className="btn-main-primary"
							>
								DOWNLOAD
							</Button>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No File Pdf" />
			);
		}
	};

	return (
		<Modal
			classNames={{
				wrapper: "modal-form-pdf-file-preview",
			}}
			width={pdfWidth + 30}
			title="PDF File Preview"
			open={toggleModalFilePreview.open}
			onCancel={() =>
				setToggleModalFilePreview({
					open: false,
					data: null,
				})
			}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={0}
					onClick={() => {
						setToggleModalFilePreview({
							open: false,
							data: null,
						});
						onFinish();
					}}
				>
					Close
				</Button>,
			]}
			forceRender
		>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24}>
					{handlePdfFileRender()}
				</Col>
			</Row>
		</Modal>
	);
}
