import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
	Modal,
	Button,
	Form,
	Row,
	Col,
	Upload,
	notification,
	Input,
	Space,
	Divider,
	Image as AntdImage,
	Progress,
	Flex,
	TreeSelect,
	Checkbox,
} from "antd";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrowser, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";

import { POST } from "../../../../providers/useAxiosQuery";
import { apiUrl } from "../../../../providers/companyInfo";
import FloatInput from "../../../../providers/FloatInput";
import FloatQuill from "../../../../providers/FloatQuill";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatTextArea from "../../../../providers/FloatTextArea";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";

const { SHOW_PARENT } = TreeSelect;

export default function ModalFormCompose(props) {
	const {
		toggleModalFormCompose,
		setToggleModalFormCompose,
		dataDepartments,
		dataTreeUser,
	} = props;

	const location = useLocation();
	const [form] = Form.useForm();
	const [onProgress, setOnProgress] = useState(0);
	const [emailAttachment, setEmailAttachment] = useState([]);
	const [newType, setNewType] = useState("");
	const [selectedTreeData, setSelectedTreeData] = useState([]); /// THIS

	const treeData = [
		{
			title: "All",
			value: "All",
			key: "All",
			disabled: selectedTreeData.includes("Department"),
		},
		{
			title: "Department",
			value: "Department",
			key: "Department",
			children: dataTreeUser
				? dataTreeUser
						.filter(
							(item) =>
								item.category === "Department" &&
								item.children &&
								item.children.length > 0
						)
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((item) => ({
							...item,
							disabled: selectedTreeData.includes("All"),
						}))
				: [],
			disabled: selectedTreeData.includes("All"),
		},
		{
			title: "Individual",
			value: "Individual",
			key: "Individual",
			children: dataTreeUser
				? dataTreeUser.filter(
						(item) => item.title != "All" && item.category != "Department"
				  )
				: [],
		},
	];

	const [currentType, setCurrentType] = useState([
		{ label: "For Signature", value: "For Signature" },
		{ label: "Memo", value: "Memo" },
	]);

	const handleAddType = () => {
		if (newType) {
			setCurrentType((prevOptions) => [
				...prevOptions,
				{ label: newType, value: newType },
			]);
			setNewType("");
		}
	};

	const { mutate: mutateDocument, isLoading: isLoadingUpdateDocument } = POST(
		`api/documents`,
		"documents_received_list",
		true,
		() => {},
		setOnProgress
	);

	const onFinish = (values) => {
		let data = new FormData();

		data.append("type", values.type);
		data.append("subject", values.subject ? values.subject : "");
		data.append("email_body", values.email_body ? values.email_body : "");

		let recipients = [];
		let recipientFilter = values.recipient_id;

		if (recipientFilter.find((x) => x === "All")) {
			if (dataTreeUser) {
				dataTreeUser
					.filter((x) => x.title === "All")
					.forEach((item) => {
						item.users.forEach((user) => {
							if (!recipients.find((x) => x === user.id)) {
								recipients.push(user.id);
							}
						});
					});
			}
		}

		if (recipientFilter.find((x) => x === "Department")) {
			if (dataTreeUser) {
				dataTreeUser
					.filter((item) => item.category === "Department")
					.forEach((item) => {
						item.children.forEach((deptUser) => {
							if (!recipients.find((x) => x === deptUser.id)) {
								recipients.push(deptUser.id);
							}
						});
					});
			}
		}

		if (recipientFilter.find((x) => x === "Individual")) {
			if (dataTreeUser) {
				dataTreeUser
					.filter((item) => item.category === "Individual")
					.forEach((item) => {
						recipients.push(item.id);
					});
			}
		}

		if (
			recipientFilter.filter(
				(x) => x !== "Individual" && x !== "Department" && x !== "All"
			)
		) {
			if (dataTreeUser) {
				let users = dataTreeUser.find((x) => x.title === "All");

				if (users) {
					users.users
						.filter(
							(x) =>
								recipientFilter.includes(x.value) ||
								recipientFilter.includes(x.department)
						)
						.forEach((user) => {
							let findExist = recipients.find((x) => x === user.id);

							if (!findExist) {
								recipients.push(user.id);
							}
						});
				}
			}
		}

		// data.append("recipient_id", recipients.join(","));

		data.append(
			"recipient_id",
			recipients.join(",")
				? recipients.join(",")
				: values.recipient_id.map((item) => item.value).join(",")
		);

		data.append(
			"date_completion",
			dayjs(values.date_completion).format("YYYY-MM-DD HH:mm:ss")
		);

		data.append(
			"id",
			toggleModalFormCompose.data && toggleModalFormCompose.data.id
				? toggleModalFormCompose.data.id
				: ""
		);

		emailAttachment.forEach((item) => {
			if (item.originFileObj) {
				data.append("attachments[]", item.originFileObj);
			} else {
				data.append("attachments[]", item.id);
			}
		});

		mutateDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalFormCompose({
						open: false,
						data: null,
					});
					form.resetFields();

					notification.success({
						message: "Document",
						description: res.message,
					});
					setOnProgress(0);
					setSelectedTreeData([]);
					setEmailAttachment([]);
				} else {
					notification.error({
						message: "Document",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const {
		mutate: mutateEventAttachment,
		isLoading: isLoadingECOMAttachment,
	} = POST(`api/ecom_attachment_delete`, "ecom_attachment_delete");

	const handleDeleteAttachment = (item, index) => {
		if (item.id) {
			mutateEventAttachment(item, {
				onSuccess: (res) => {
					if (res.success) {
						setEmailAttachment((prev) => prev.filter((_, i) => i !== index));
						notification.success({
							message: "Event Attachment",
							description: res.message,
						});
					} else {
						notification.error({
							message: "Event Attachment",
							description: res.message,
						});
					}
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		} else {
			setEmailAttachment((prev) => prev.filter((_, i) => i !== index));
		}
	};

	useEffect(() => {
		if (toggleModalFormCompose.open) {
			if (
				toggleModalFormCompose &&
				toggleModalFormCompose.data &&
				toggleModalFormCompose.data.id !== null
			) {
				let recipients = [];

				if (
					toggleModalFormCompose.data &&
					toggleModalFormCompose.data.document_recipients &&
					toggleModalFormCompose.data.document_recipients.length > 0
				) {
					recipients = toggleModalFormCompose.data.document_recipients.map(
						(item) => ({
							value: item.profile.id,
							label: item.profile.user.email,
						})
					);
				}

				// Fix: Ensure attachments are correctly mapped
				if (
					toggleModalFormCompose.data &&
					toggleModalFormCompose.data.attachments.length > 0
				) {
					let attachments = toggleModalFormCompose.data.attachments.map(
						(item) => {
							// If the file is already uploaded, it won't have originFileObj, so we map it accordingly
							return {
								id: item.id,
								uid: item.id,
								name: item.file_name,
								src: apiUrl(item.file_path),
								url: apiUrl(item.file_path),
								file_path: item.file_path, // File path for server reference
								originFileObj: null, // Already uploaded files don’t need this
							};
						}
					);

					setEmailAttachment(attachments);
				}

				// Set initial form values
				form.setFieldsValue({
					...toggleModalFormCompose.data,
					date_completion: toggleModalFormCompose.data.date_completion
						? dayjs(toggleModalFormCompose.data.date_completion)
						: null,
					recipient_id: recipients,
				});
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormCompose]);

	const onChange = (newValue) => {
		setSelectedTreeData(newValue);
	};

	return (
		<Modal
			title="FORM COMPOSE EMAIL"
			open={toggleModalFormCompose.open}
			onCancel={() => {
				setToggleModalFormCompose({
					open: false,
					data: null,
				});
				form.resetFields();
				setEmailAttachment([]);
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalFormCompose({
							open: false,
							data: null,
						});
						form.resetFields();
						setEmailAttachment([]);
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={isLoadingUpdateDocument}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form
				form={form}
				onFinish={onFinish}
				initialValues={{ note: "N/A" }}
				scrollToFirstError
			>
				<Row gutter={[12, 0]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="type" rules={[validateRules.required]}>
							<FloatSelect
								label="Type"
								placeholder="Type"
								options={currentType}
								required
								dropdownRender={(menu) => (
									<>
										{menu}
										<Divider
											style={{
												margin: "8px 0",
											}}
										/>
										<Space
											style={{
												padding: "0 8px 4px",
											}}
										>
											<Input
												style={{
													border: "1px solid",
												}}
												value={newType}
												onChange={(e) => setNewType(e.target.value)}
											/>
											<Button
												type="primary"
												icon={<FontAwesomeIcon icon={faPlus} />}
												name="btn-add-type"
												onClick={handleAddType}
											>
												Add Type
											</Button>
										</Space>
									</>
								)}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="recipient_id" rules={[validateRules.required]}>
							<TreeSelect
								value={selectedTreeData}
								onChange={onChange}
								treeData={treeData}
								treeCheckable
								treeLine
								showCheckedStrategy={SHOW_PARENT}
								placeholder="To"
								popupMatchSelectWidth
								allowClear
								notFoundContent="No user found"
								required
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="subject" rules={[validateRules.required]}>
							<FloatInput label="Subject" placeholder="Subject" required />
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="email_body">
							<FloatQuill label="Email Body" placeholder="Email Body" />
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="date_completion" rules={[validateRules.required]}>
							<FloatDatePicker
								className="dateCompletion"
								label="Date Completion"
								placeholder="Date Completion"
								required={true}
								allowClear
								format={{
									format: "MM-DD-YYYY",
									type: "mask",
								}}
								disabledDate={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Upload.Dragger
							action={false}
							multiple={true}
							showUploadList={false}
							className="event-email-attachment-upload"
							listType="picture-card"
							accept="application/pdf"
							maxCount={3}
							fileList={emailAttachment}
							onChange={({ fileList: newFileList }) => {
								const newFileListPromises = newFileList.map((item) => {
									return new Promise((resolve, reject) => {
										const file = item.originFileObj;
										const isPDF = file.type === "application/pdf";
										const isLt5M = file.size / 1024 / 1024 < 5;

										if (!isPDF) {
											notification.error({
												message: file.name,
												description: `${file.name} is not a PDF file. Please upload a valid PDF file.`,
											});
											reject({
												status: "error",
												message: `${file.name} is not a PDF file. Please upload a valid PDF file.`,
											});
										} else if (!isLt5M) {
											notification.error({
												message: file.name,
												description: `${file.name} is larger than 5 MB. Please upload a file smaller than 5 MB.`,
											});
											reject({
												status: "error",
												message: `${file.name} is larger than 5 MB. Please upload a file smaller than 5 MB.`,
											});
										} else {
											resolve({
												...item,
												status: "success",
											});
										}
									});
								});

								let promise = Promise.all(
									newFileListPromises.map((p) => p.catch((e) => e))
								).then((files) => files.filter((f) => f.status === "success"));

								promise.then((files) => {
									setEmailAttachment(files);
								});
							}}
							beforeUpload={(file) => {
								return false;
							}}
						>
							Upload A Maximum of 3 PDF Files (5MB each) for email attachments
						</Upload.Dragger>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<div className="event-email-attachment-upload__preview">
								{emailAttachment.map((item, index) => {
									return (
										<div key={index} className="event-email-attachment">
											<Flex justify="space-between">
												<Flex align="center" gap={10}>
													<Button
														type="link"
														onClick={() => {
															window.open(
																item.src
																	? item.src
																	: URL.createObjectURL(item.originFileObj)
															);
														}}
													>
														<FontAwesomeIcon icon={faBrowser} />
													</Button>
													<div className="name">{item.name}</div>
												</Flex>

												<Button
													onClick={() => handleDeleteAttachment(item, index)}
													className="btn-delete"
													type="link"
													loading={isLoadingECOMAttachment}
												>
													<FontAwesomeIcon icon={faTrash} />
												</Button>
											</Flex>
										</div>
									);
								})}
							</div>
						</Col>

						<Progress
							percent={onProgress}
							status="active"
							strokeColor={{
								from: "#108ee9",
								to: "#87d068",
							}}
						/>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
