import React from "react";
import {
	Button,
	Col,
	Flex,
	Popconfirm,
	Row,
	Table,
	Tooltip,
	Typography,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSyncAlt,
	faBoxArchive,
	faTrashUndo,
	faPaperclip,
	faEye,
	faStar as faStarRegular,
	faEnvelope,
	faEnvelopeDot,
	faEnvelopeOpen,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";

import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../providers/CustomTableFilter";
import QuillOutputCollapse from "../../../providers/QuillOutputCollapse";
import { userData } from "../../../providers/companyInfo";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

export default function TableMail(props) {
	const {
		tableFilter,
		setTableFilter,
		selectedRowKeys,
		setSelectedRowKeys,
		handleSelectedArchived,
		isLoadingDocument,
		handleSelectedMarkRead,
		isLoadingMarkReadEmail,
		handleSelectedMarkImportant,
		//
		refetchSourceDocument,
		onColumnClick,
		dataDocument,
		toggleShowAll,
		expandedRows,
		activeTab,
		setToggleModalFilePreview,
	} = props;

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper">
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Flex justify="space-between" className="tbl-top-filter">
						{activeTab === "AllMail" ? (
							<Typography.Title level={3}>All Mail</Typography.Title>
						) : activeTab === "Important" ? (
							<Typography.Title level={3}>Important</Typography.Title>
						) : activeTab === "Inbox" ? (
							<Typography.Title level={3}>Inbox</Typography.Title>
						) : activeTab === "Sent" ? (
							<Typography.Title level={3}>Sent</Typography.Title>
						) : activeTab === "Forwarded" ? (
							<Typography.Title level={3}>Forwarded</Typography.Title>
						) : activeTab === "Archived" ? (
							<Typography.Title level={3}>Archived</Typography.Title>
						) : null}

						<TableGlobalSearch
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</Flex>

					{/* Refresh | Mark Read | Mark Important | Archived */}
					<Flex justify="space-between" className="tbl-top-filter">
						<Flex className="flex-extra-filter">
							<Tooltip title="Refresh">
								<Button
									type="link"
									name="btn_refresh"
									className="p-0"
									icon={<FontAwesomeIcon icon={faSyncAlt} />}
									onClick={() => refetchSourceDocument()}
								>
									Refresh
								</Button>
							</Tooltip>

							{selectedRowKeys.length > 0 && (
								<>
									{activeTab !== "Archived" && (
										<>
											<Tooltip
												title={
													selectedRowKeys.some(
														(key) =>
															dataDocument.data.data.find(
																(item) => item.id === key
															)?.marked_read === 0
													)
														? "Mark as Read"
														: "Mark as Unread"
												}
											>
												<Popconfirm
													title={() => {
														const hasUnread = selectedRowKeys.some(
															(key) =>
																dataDocument.data.data.find(
																	(item) => item.id === key
																)?.marked_read === 0
														);

														return (
															<>
																Are you sure you want to
																<br />
																{hasUnread
																	? "mark as read"
																	: "mark as unread"}{" "}
																the selected{" "}
																{selectedRowKeys.length > 1
																	? "emails"
																	: "email"}
																?
															</>
														);
													}}
													okText="Yes"
													cancelText="No"
													onConfirm={() => {
														handleSelectedMarkRead();
													}}
												>
													<Button
														type="link"
														name="btn_read"
														loading={isLoadingMarkReadEmail}
														icon={
															selectedRowKeys.some(
																(key) =>
																	dataDocument.data.data.find(
																		(item) => item.id === key
																	)?.marked_read === 0
															) ? (
																<FontAwesomeIcon icon={faEnvelopeDot} />
															) : (
																<FontAwesomeIcon icon={faEnvelopeOpen} />
															)
														}
													></Button>
												</Popconfirm>
											</Tooltip>

											<Tooltip
												title={
													selectedRowKeys.some(
														(key) =>
															dataDocument.data.data.find(
																(item) => item.id === key
															)?.marked_important === 0
													)
														? "Mark as Important"
														: "Mark as Unimportant"
												}
											>
												<Popconfirm
													title={() => {
														const hasUnImportant = selectedRowKeys.some(
															(key) =>
																dataDocument.data.data.find(
																	(item) => item.id === key
																)?.marked_important === 0
														);

														return (
															<>
																Are you sure you want
																<br />
																to{" "}
																{hasUnImportant
																	? "mark as important"
																	: "remove"}{" "}
																the selected <br />
																{selectedRowKeys.length > 1
																	? "emails"
																	: "email"}{" "}
																{hasUnImportant ? "" : "from important"}?
															</>
														);
													}}
													okText="Yes"
													cancelText="No"
													onConfirm={() => {
														handleSelectedMarkImportant();
													}}
												>
													<Button
														type="link"
														name="btn_read"
														// loading={isLoadingMarkImportant}
														icon={<FontAwesomeIcon icon={faStarRegular} />}
													></Button>
												</Popconfirm>
											</Tooltip>
										</>
									)}

									<Tooltip
										title={
											tableFilter.activeTab === "Archived"
												? "Restore"
												: "Archived"
										}
									>
										<Popconfirm
											title={
												<>
													Are you sure you want to
													<br />
													{tableFilter.activeTab === "Archived"
														? "restore"
														: "archive"}{" "}
													the selected{" "}
													{selectedRowKeys.length > 1 ? "emails" : "email"}?
												</>
											}
											okText="Yes"
											cancelText="No"
											onConfirm={() => {
												handleSelectedArchived();
											}}
										>
											<Button
												type="link"
												name="btn_active_archive"
												loading={isLoadingDocument}
												icon={
													tableFilter.activeTab === "Archived" ? (
														<FontAwesomeIcon icon={faTrashUndo} />
													) : (
														<FontAwesomeIcon icon={faBoxArchive} />
													)
												}
											></Button>
										</Popconfirm>
									</Tooltip>
								</>
							)}
						</Flex>

						<Flex gap={10}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</Flex>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24}>
							<Table
								id="tblApplicant"
								className="ant-table-default ant-table-striped table-vertical-top"
								dataSource={dataDocument && dataDocument.data.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky={{
									offsetHeader: 0,
								}}
								rowSelection={{
									selectedRowKeys,
									onChange: (selectedRowKeys) => {
										setSelectedRowKeys(selectedRowKeys);
									},
								}}
							>
								{activeTab === "Inbox" ? (
									<Table.Column
										title="From"
										key="from_id"
										dataIndex="from_id"
										width={200}
										sorter={(a, b) => {
											const fromA = a.from_id || "";
											const fromB = b.from_id || "";
											return fromA.localeCompare(fromB);
										}}
										render={(text, record) => {
											console.log("record: ", record);

											return (
												<Flex gap={10} align="center">
													<span
														onClick={() => {
															onColumnClick(record.id);
															if (record.marked_read === 0) {
																handleSelectedMarkRead([record.id]);
															}
														}}
														style={
															record.marked_read === 0
																? { fontWeight: "bold" }
																: {}
														}
													>
														{record.from_fullname}
													</span>
													<Button
														type="link"
														icon={
															<FontAwesomeIcon
																icon={
																	record.marked_important === 1
																		? faStar
																		: faStarRegular
																}
																style={{
																	color:
																		record.marked_important === 1
																			? "#fbbc04"
																			: "#B7B7B7",
																}}
															/>
														}
														onClick={() =>
															handleSelectedMarkImportant([record.id])
														}
													/>
												</Flex>
											);
										}}
									/>
								) : activeTab === "Sent" ? (
									<Table.Column
										title="To"
										key="recipient_id"
										dataIndex="recipient_id"
										width={250}
										render={(text, record) => {
											const showAll = expandedRows[record.id] || false;
											let recipients = [];

											if (
												record &&
												record.document_recipients &&
												record.document_recipients.length > 0
											) {
												recipients = record.document_recipients.map((item) => {
													const firstname = item.profile?.firstname || "";
													const middlename = item.profile?.middlename
														? item.profile.middlename + " "
														: "";
													const lastname = item.profile?.lastname || "";
													return {
														fullname: firstname + " " + middlename + lastname,
													};
												});
											}

											const displayedRecipients = showAll
												? recipients
												: recipients.slice(0, 1);

											return (
												<>
													<Flex
														gap={10}
														align={showAll ? "flex-start" : "center"}
														vertical={showAll ? true : false}
													>
														{displayedRecipients.map((recipient, index) => (
															<div
																key={index}
																onClick={() => onColumnClick(record.id)}
															>
																{recipient.fullname}
															</div>
														))}
														{recipients.length > 0 && (
															<Button
																type="link"
																className="pl-0"
																onClick={() => toggleShowAll(record.id)}
															>
																{showAll ? "Show Less" : "Show More"}
															</Button>
														)}
														<Button
															type="link"
															icon={
																<FontAwesomeIcon
																	icon={
																		record.marked_important === 1
																			? faStar
																			: faStarRegular
																	}
																	style={{
																		display: showAll ? "flex" : "",
																		justifyContent: showAll ? "center" : "",
																		color:
																			record.marked_important === 1
																				? "#fbbc04"
																				: "#B7B7B7",
																	}}
																/>
															}
															onClick={() =>
																handleSelectedMarkImportant([record.id])
															}
														/>
													</Flex>
												</>
											);
										}}
									/>
								) : activeTab === "Forwarded" ? (
									<Table.Column
										title="To"
										key="recipient_id"
										dataIndex="recipient_id"
										width={250}
										render={(text, record) => {
											const showAll = expandedRows[record.id] || false;
											let recipients = [];

											if (
												record &&
												record.document_forwardeds &&
												record.document_forwardeds.length > 0
											) {
												recipients = record.document_forwardeds.map((item) => {
													const firstname =
														item.forward_to_user?.profile?.firstname || "";
													const middlename = item.forward_to_user?.profile
														?.middlename
														? item.forward_to_user.mprofile?.iddlename + " "
														: "";
													const lastname =
														item.forward_to_user?.profile?.lastname || "";
													return {
														fullname: firstname + " " + middlename + lastname,
													};
												});
											}

											const displayedRecipients = showAll
												? recipients
												: recipients.slice(0, 1);

											return (
												<Flex>
													<Flex
														gap={10}
														align={showAll ? "flex-start" : "center"}
														vertical={showAll ? true : false}
													>
														{displayedRecipients.map((recipient, index) => (
															<div
																key={index}
																onClick={() => onColumnClick(record.id)}
															>
																{recipient.fullname}
															</div>
														))}
														{recipients.length > 0 && (
															<Button
																type="link"
																className="pl-0"
																onClick={() => toggleShowAll(record.id)}
															>
																{showAll ? "Show Less" : "Show More"}
															</Button>
														)}
													</Flex>
													<Button
														type="link"
														icon={
															<FontAwesomeIcon
																icon={
																	record.marked_important === 1
																		? faStar
																		: faStarRegular
																}
																style={{
																	display: showAll ? "flex" : "",
																	justifyContent: showAll ? "center" : "",
																	color:
																		record.marked_important === 1
																			? "#fbbc04"
																			: "#B7B7B7",
																}}
															/>
														}
														onClick={() =>
															handleSelectedMarkImportant([record.id])
														}
													/>
												</Flex>
											);
										}}
									/>
								) : activeTab === "AllMail" ||
								  activeTab === "Archived" ||
								  activeTab === "Important" ? (
									<>
										<Table.Column
											key="recipient_id"
											dataIndex="recipient_id"
											width={250}
											render={(text, record) => {
												console.log("record: ", record);

												const showAll = expandedRows[record.id] || false;
												let recipients = [];

												if (
													record &&
													record.document_recipients &&
													record.document_recipients.length > 0
												) {
													recipients = record.document_recipients.map(
														(item) => {
															const firstname = item.profile?.firstname || "";
															const middlename = item.profile?.middlename
																? item.profile.middlename + " "
																: "";
															const lastname = item.profile?.lastname || "";
															return {
																fullname:
																	firstname + " " + middlename + lastname,
															};
														}
													);
												}

												const displayedRecipients = showAll
													? recipients
													: recipients.slice(0, 1);

												return (
													<>
														{record.created_by === userData().id ? (
															<Flex
																gap={10}
																align={showAll ? "flex-start" : "center"}
																vertical={showAll ? true : false}
															>
																To:
																{displayedRecipients.map((recipient, index) => (
																	<div
																		key={index}
																		onClick={() => onColumnClick(record.id)}
																	>
																		{recipient.fullname}
																	</div>
																))}
																{recipients.length > 0 && (
																	<Button
																		type="link"
																		className="pl-0"
																		onClick={() => toggleShowAll(record.id)}
																	>
																		{showAll ? "Show Less" : "Show More"}
																	</Button>
																)}
																<Button
																	type="link"
																	icon={
																		<FontAwesomeIcon
																			icon={
																				record.marked_important === 1
																					? faStar
																					: faStarRegular
																			}
																			style={{
																				display: showAll ? "flex" : "",
																				justifyContent: showAll ? "center" : "",
																				color:
																					record.marked_important === 1
																						? "#fbbc04"
																						: "#B7B7B7",
																			}}
																		/>
																	}
																	onClick={() =>
																		handleSelectedMarkImportant([record.id])
																	}
																/>
															</Flex>
														) : (
															<Flex gap={10} align="center">
																<div
																	onClick={() => {
																		onColumnClick(record.id);
																		if (record.marked_read === 0) {
																			handleSelectedMarkRead([record.id]);
																		}
																	}}
																	style={
																		record.marked_read === 0
																			? { fontWeight: "bold" }
																			: {}
																	}
																>
																	From: {record.from_fullname}
																</div>
																<Button
																	type="link"
																	icon={
																		<FontAwesomeIcon
																			icon={
																				record.marked_important === 1
																					? faStar
																					: faStarRegular
																			}
																			style={{
																				display: showAll ? "flex" : "",
																				justifyContent: showAll ? "center" : "",
																				color:
																					record.marked_important === 1
																						? "#fbbc04"
																						: "#B7B7B7",
																			}}
																		/>
																	}
																	onClick={() =>
																		handleSelectedMarkImportant([record.id])
																	}
																/>
															</Flex>
														)}
													</>
												);
											}}
										/>
									</>
								) : null}

								<Table.Column
									title="Subject"
									key="subject"
									dataIndex="subject"
									sorter={(a, b) => a.subject.localeCompare(b.subject)}
									render={(_, record) => {
										let subject = record.subject;
										return <QuillOutputCollapse quillOutput={subject} />;
									}}
									width={200}
								/>

								<Table.Column
									title="Attachment"
									key="attachment"
									dataIndex="attachment"
									sorter={(a, b) => a.subject.localeCompare(b.subject)}
									width={50}
									align="center"
									render={(_, record) => {
										return record.attachments.length > 0 ? (
											// <Button
											// 	type="link"
											// 	icon={<FontAwesomeIcon icon={faPaperclip} />}
											// 	onClick={() =>
											// 		setToggleModalFilePreview({
											// 			open: true,
											// 			data: record,
											// 		})
											// 	}
											// ></Button>
											<FontAwesomeIcon icon={faPaperclip} />
										) : null;
									}}
								/>

								{activeTab === "Archived" ? (
									<Table.Column
										title="Date Deleted"
										key="deleted_at"
										dataIndex="deleted_at"
										sorter={(a, b) =>
											dayjs(a.deleted_at).unix() - dayjs(b.deleted_at).unix()
										}
										width={100}
										align="right"
										render={(text, record) => {
											const dateCreated = record.deleted_at
												? dayjs(record.deleted_at)
												: null;
											const today = dayjs();
											const yesterday = dayjs().subtract(1, "day");

											let displayDate = "";
											if (dateCreated) {
												if (dateCreated.isSame(today, "day")) {
													displayDate = dateCreated.format("hh:mm A");
												} else if (dateCreated.isSame(yesterday, "day")) {
													displayDate = dateCreated.format("MMM DD");
												} else {
													displayDate = dateCreated.format("MMMM DD, YYYY");
												}
											}

											return <>{displayDate}</>;
										}}
									/>
								) : (
									<Table.Column
										title="Date Sent"
										key="created_at"
										dataIndex="created_at"
										sorter={(a, b) =>
											dayjs(a.created_at).unix() - dayjs(b.created_at).unix()
										}
										width={100}
										align="right"
										render={(text, record) => {
											const dateCreated = record.created_at
												? dayjs(record.created_at)
												: null;
											const today = dayjs();
											const yesterday = dayjs().subtract(1, "day");

											let displayDate = "";
											if (dateCreated) {
												if (dateCreated.isSame(today, "day")) {
													displayDate = dateCreated.format("hh:mm A");
												} else if (dateCreated.isSame(yesterday, "day")) {
													displayDate = dateCreated.format("MMM DD");
												} else {
													displayDate = dateCreated.format("MMMM DD, YYYY");
												}
											}

											return <>{displayDate}</>;
										}}
									/>
								)}
							</Table>
						</Col>

						<Col xs={24} sm={24} md={24}>
							<Flex justify="flex-end">
								<div className="tbl-bottom-filter">
									<TablePagination
										tableFilter={tableFilter}
										setTableFilter={setTableFilter}
										setPaginationTotal={
											dataDocument && dataDocument?.data?.total
										}
										showLessItems={true}
										showSizeChanger={false}
										tblIdWrapper="tbl_wrapper"
									/>
								</div>
							</Flex>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
