import { faCogs, faEnvelopeDot } from "@fortawesome/pro-regular-svg-icons";
import {
	faHome,
	faChartSimple,
	faInbox,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "antd";

export const adminHeaderMenuLeft = (
	<>
		{/* <div className="ant-menu-left-icon">
            <Link to="/subscribers/current">
                <span className="anticon">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <Typography.Text>Subscribers</Typography.Text>
            </Link>
        </div> */}
	</>
);

export const adminHeaderDropDownMenuLeft = () => {
	const items = [
		// {
		//     key: "/subscribers/current",
		//     icon: <FontAwesomeIcon icon={faUsers} />,
		//     label: <Link to="/subscribers/current">Subscribers</Link>,
		// },
	];

	return <Menu items={items} />;
};

export const adminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
		moduleCode: "M-01",
	},
	// {
	// 	title: "Mailbox",
	// 	path: "/mailbox",
	// 	icon: <FontAwesomeIcon icon={faInbox} />,
	// 	children: [
	// 		{
	// 			title: "All",
	// 			path: "/mailbox/all",
	// 			moduleCode: "M-01",
	// 		},
	// 		{
	// 			title: "Inbox",
	// 			path: "/mailbox/received",
	// 			moduleCode: "M-01",
	// 		},
	// 		{
	// 			title: "Sent",
	// 			path: "/mailbox/sent",
	// 			moduleCode: "M-01",
	// 		},
	// 		{
	// 			title: "Forwarded",
	// 			path: "/mailbox/forwarded",
	// 			moduleCode: "M-01",
	// 		},
	// 		// {
	// 		// 	title: "Draft",
	// 		// 	path: "/mailbox/draft",
	// 		// 	moduleCode: "M-01",
	// 		// },
	// 		// {
	// 		// 	title: "Starred",
	// 		// 	path: "/mailbox/starred",
	// 		// 	moduleCode: "M-01",
	// 		// },
	// 	],
	// },
	{
		title: "Mailbox",
		path: "/mailbox",
		icon: <FontAwesomeIcon icon={faInbox} />,
		moduleCode: "M-01",
	},
	{
		title: "Report",
		path: "/report",
		icon: <FontAwesomeIcon icon={faChartSimple} />,
		moduleCode: "M-01",
	},
	{
		title: "System Settings",
		path: "/system-settings",
		icon: <FontAwesomeIcon icon={faCogs} />,
		children: [
			{
				title: "Department",
				path: "/system-settings/department",
				moduleCode: "M-01",
			},
		],
	},
	{
		title: "Failed Sent Emails",
		path: "/failed-sent-emails",
		icon: <FontAwesomeIcon icon={faEnvelopeDot} />,
		moduleCode: "M-01",
	},
];
