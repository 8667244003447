import React, { useState } from "react";
import {
	Button,
	Col,
	Divider,
	Flex,
	Form,
	notification,
	Row,
	Tooltip,
	Typography,
} from "antd";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faBoxArchive,
	faEllipsis,
	faEllipsisH,
	faFilePdf,
	faPaperclip,
	faPrint,
	faReply,
} from "@fortawesome/pro-regular-svg-icons";
import { apiUrl, userData } from "../../../providers/companyInfo";
import FloatTextArea from "../../../providers/FloatTextArea";
import validateRules from "../../../providers/validateRules";
import { POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageMailContent(props) {
	const {
		activeTab,
		dataDocument,
		selectedDocumentID,
		setToggleModalFileForward,
		setToggleModalFilePreview,
		handleSelectedArchived,
	} = props;

	const [form] = Form.useForm();
	const [showMore, setShowMore] = useState(false);
	const [showForwarded, setShowForwarded] = useState(false);
	const [showReplyField, setShowReplyField] = useState(false);

	const handleDownload = (filePath) => {
		// Logic to download the attachment
		const link = document.createElement("a");
		link.href = filePath;
		link.setAttribute("download", filePath.split("/").pop());
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	const handleShowMore = () => {
		setShowMore(!showMore);
	};

	const handleReplyClick = () => {
		setShowReplyField(true);
	};

	const { mutate: mutateDocument } = POST(
		`api/document_recipients`,
		"documents_received_list",
		true,
		() => {}
	);

	const onFinish = (values) => {
		let data = new FormData();

		data.append("sent_by_id", values.sent_by_id[0].value);
		data.append("note", values.note);

		mutateDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					form.resetFields();

					notification.success({
						message: "Mail",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Mail",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const selectedEmail =
		dataDocument.find((item) => item.id === selectedDocumentID) || {};

	// console.log("selectedEmail: ", selectedEmail);
	// console.log("selectedDocumentID: ", selectedDocumentID);

	return (
		<>
			<Form
				form={form}
				onFinish={onFinish}
				// initialValues={{ note: "N/A" }}
				scrollToFirstError
			>
				<Row gutter={[12, 0]}>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						xl={24}
						className="header-email-content"
					>
						<Flex justify="space-between" className="tbl-top-filter">
							<Typography.Title level={3}>View Message</Typography.Title>

							<Flex className="flex-extra-filter">
								<Tooltip title="Reply">
									<Button
										type="link"
										name="btn_reply"
										icon={<FontAwesomeIcon icon={faReply} />}
										onClick={handleReplyClick}
										// disabled={selectedDocumentID === userData().id}
									>
										Reply
									</Button>
								</Tooltip>

								<Tooltip title="Print Email">
									<Button
										type="link"
										name="btn_print_email"
										icon={<FontAwesomeIcon icon={faPrint} />}
									></Button>
								</Tooltip>

								<Tooltip title="Move To Archived">
									<Button
										type="link"
										name="btn_active_archive"
										icon={<FontAwesomeIcon icon={faBoxArchive} />}
										onClick={() => {
											handleSelectedArchived([selectedEmail.id]);
										}}
									></Button>
								</Tooltip>
							</Flex>
						</Flex>

						<Typography.Title level={5} className="mt-0 mb-0">
							<span style={{ color: "grey", fontWeight: "400" }}>Subject:</span>{" "}
							{selectedEmail.subject}
						</Typography.Title>

						<Flex justify="space-between">
							<Col xs={24} sm={24} md={24} lg={16} xl={16} className="pl-0">
								<Typography level={5} className="mt-0">
									<strong>
										{selectedEmail.sent_by_id === userData().id ? (
											<>
												<span style={{ color: "grey", fontWeight: "400" }}>
													To:{" "}
												</span>
												{selectedEmail.document_recipients
													.slice(
														0,
														showMore
															? selectedEmail.document_recipients.length
															: 3
													)
													.map((recipient, index) => (
														<span key={index}>
															{recipient.profile?.user?.email}
															{index <
																selectedEmail.document_recipients.length - 1 &&
																", "}
														</span>
													))}
												{selectedEmail.document_recipients.length > 3 && (
													<Button type="link" onClick={handleShowMore}>
														{showMore ? "Show Less" : "Show More"}
													</Button>
												)}
											</>
										) : (
											<>
												<span style={{ color: "grey", fontWeight: "400" }}>
													From:
												</span>{" "}
												{selectedEmail.from_email}
											</>
										)}
									</strong>
								</Typography>

								{selectedEmail.document_forwardeds.length > 0 && (
									<Tooltip
										title={
											showForwarded
												? "Hide expanded content"
												: "Show trimmed content"
										}
									>
										<Button
											type="link"
											className="p-0"
											onClick={() => setShowForwarded(!showForwarded)}
										>
											<FontAwesomeIcon icon={faEllipsis} />
										</Button>
									</Tooltip>
								)}
							</Col>

							<Col xs={24} sm={24} md={24} lg={6} xl={6}>
								<Flex justify="flex-end">
									<Typography level={5} className="mt-0">
										{dayjs(selectedEmail.created_at).format(
											"hh:mmA DD MMM YYYY"
										)}
									</Typography>
								</Flex>
							</Col>
						</Flex>

						{/* {activeTab === "Forwarded" && (
							<Flex vertical>
								<Typography.Text>
									<span style={{ color: "grey", fontWeight: "400" }}>
										Forwarded To:{" "}
									</span>
									<strong>
										{selectedEmail
											? selectedEmail.document_forwardeds &&
											  selectedEmail.document_forwardeds.length > 0
												? selectedEmail.document_forwardeds.map(
														(item, index) => (
															<span key={index}>
																{item.forward_to_user?.email}
																{index <
																	selectedEmail.document_forwardeds.length -
																		1 && ", "}
															</span>
														)
												  )
												: ""
											: null}
									</strong>
								</Typography.Text>
							</Flex>
						)} */}
					</Col>

					<Divider />

					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-0">
						<div
							dangerouslySetInnerHTML={{ __html: selectedEmail.email_body }}
						/>
					</Col>

					<Divider />

					{/* Attachments Section */}
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex align="center">
							<FontAwesomeIcon icon={faPaperclip} />{" "}
							{selectedEmail.attachments &&
							selectedEmail.attachments.length > 0 ? (
								<span>
									{selectedEmail.attachments.length} Attachment
									{selectedEmail.attachments.length > 1 ? "s -" : " -"}
								</span>
							) : (
								<span>Attachments</span>
							)}
							<Button type="link">Download all</Button>
							<Button type="link" className="pl-0">
								View all
							</Button>
						</Flex>
					</Col>

					<Flex gap={15} className="w-25">
						{selectedEmail.attachments &&
							selectedEmail.attachments.map((attachment, attachmentIndex) => {
								return (
									<Col
										key={attachmentIndex}
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className="pdf-file mt-10"
										style={{
											border: "1px solid #f0f0f0",
											padding: "0px",
										}}
										onClick={() => {
											let pdf = null;

											if (selectedEmail.attachments.length > 0) {
												pdf = selectedEmail.attachments[attachmentIndex].pdf;
											}

											let signature = null;
											if (
												selectedEmail.document_recipients &&
												selectedEmail.document_recipients.length > 0
											) {
												signature = selectedEmail.document_recipients
													.filter(
														(item) => item.profile?.user_id === userData().id
													)
													.map((item) =>
														item.profile.attachments &&
														item.profile.attachments.length > 0
															? item.profile.attachments[0].file_path
															: null
													);
											}

											console.log("signature: ", signature);

											let document_signatures = [];

											if (
												selectedEmail.document_signatures &&
												selectedEmail.document_signatures.length > 0
											) {
												document_signatures = selectedEmail.document_signatures.filter(
													(item) =>
														item.document_recipient_id === selectedDocumentID
												);
											}

											setToggleModalFilePreview({
												open: true,
												data: {
													...selectedEmail.attachments[attachmentIndex],
													document_signatures: document_signatures,
													pdf,
													signature: signature ? apiUrl(signature) : "",
												},
											});
										}}
									>
										<Flex justify="center" style={{ padding: "40px" }}>
											<FontAwesomeIcon icon={faFilePdf} size="3x" />
										</Flex>

										<Flex
											justify="flex-start"
											vertical
											style={{ background: "#2c2c2c1c", padding: "10px" }}
										>
											<Col xs={24} sm={24} md={24} lg={24} xl={24}>
												<Button
													type="link"
													className="pl-0"
													onClick={() => handleDownload(attachment.file_path)}
													// onClick={(e) => {
													// 	e.stopPropagation(); // Prevent triggering the parent onClick
													// 	handleDownload(attachment.file_path);
													// }}
												>
													{attachment.file_name}
												</Button>
											</Col>

											<Col xs={24} sm={24} md={24} lg={24} xl={24}>
												<Typography.Text type="secondary">
													Added:{" "}
													{dayjs(attachment.created_at).format("MMM DD, YYYY")}
												</Typography.Text>
											</Col>
										</Flex>
									</Col>
								);
							})}
					</Flex>

					<Divider />

					{activeTab !== "Sent" &&
						dataDocument &&
						dataDocument.length > 0 &&
						dataDocument.map((item, index) =>
							item.id === selectedDocumentID ? (
								<Col
									key={index}
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									className="pl-0"
								>
									{item.document_forwardeds &&
										item.document_forwardeds.length > 0 && (
											<>
												{showForwarded &&
													(() => {
														const mailNotes = new Map();

														item.document_forwardeds.forEach((forwarded) => {
															const note = forwarded.notes;
															const email = forwarded.forward_to_user?.email;

															if (mailNotes.has(note)) {
																mailNotes.set(note, [
																	...new Set([...mailNotes.get(note), email]),
																]);
															} else {
																mailNotes.set(note, [email]);
															}
														});

														const uniqueForwardeds = Array.from(
															mailNotes.entries()
														).map(([note, emails], index) => ({
															note,
															emails,
															forwarded: item.document_forwardeds.find(
																(f) => f.notes === note
															),
														}));

														return uniqueForwardeds.map(
															({ note, emails, forwarded }, index) => (
																<React.Fragment key={index}>
																	<Typography.Text>
																		<span
																			style={{
																				color: "grey",
																				fontWeight: "400",
																			}}
																		>
																			---------- Forwarded message ---------
																		</span>
																		<br />

																		<span
																			style={{
																				color: "grey",
																				fontWeight: "400",
																			}}
																		>
																			From:{" "}
																		</span>
																		<strong>
																			{forwarded.forward_from_user?.email}
																		</strong>

																		<br />
																		<span
																			style={{
																				color: "grey",
																				fontWeight: "400",
																			}}
																		>
																			Date:{" "}
																		</span>
																		<strong>
																			{dayjs(forwarded.created_at).format(
																				"dddd, MMM DD, YYYY, hh:mmA "
																			)}
																		</strong>

																		<br />
																		<span
																			style={{
																				color: "grey",
																				fontWeight: "400",
																			}}
																		>
																			Subject:{" "}
																		</span>
																		<strong>{selectedEmail.subject}</strong>

																		<br />
																		<span
																			style={{
																				color: "grey",
																				fontWeight: "400",
																			}}
																		>
																			To:{" "}
																		</span>
																		<strong>{emails.join(", ")}</strong>
																	</Typography.Text>

																	<Col
																		xs={24}
																		sm={24}
																		md={24}
																		lg={24}
																		xl={24}
																		className="mt-40"
																	>
																		{note && <div>{note}</div>}
																	</Col>

																	<Divider />
																</React.Fragment>
															)
														);
													})()}
											</>
										)}
								</Col>
							) : null
						)}

					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						xl={24}
						className="mail-content-reply"
					>
						{showReplyField && (
							<>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} className="pl-0">
									<Form.Item
										name="note"
										className="mb-0"
										rules={[validateRules.required]}
									>
										<FloatTextArea
											label="Reply"
											placeholder="Reply"
											allowClear
										/>
									</Form.Item>
								</Col>

								<Flex gap={10}>
									<Button
										type="primary"
										className="mt-10"
										onClick={() => setShowReplyField(false)}
									>
										Send
									</Button>

									<Button
										className="mt-10"
										onClick={() => setShowReplyField(false)}
									>
										Cancle
									</Button>
								</Flex>
							</>
						)}
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex justify="flex-end">
							<Tooltip title="Reply">
								<Button
									type="link"
									name="btn_reply"
									icon={<FontAwesomeIcon icon={faReply} />}
									onClick={handleReplyClick}
									disabled={selectedDocumentID === userData().id}
								>
									Reply
								</Button>
							</Tooltip>

							<Tooltip title="Forward">
								<Button
									type="link"
									name="btn_forward"
									icon={<FontAwesomeIcon icon={faArrowRight} />}
									onClick={() =>
										setToggleModalFileForward({
											open: true,
											data: selectedEmail,
										})
									}
								>
									Forward
								</Button>
							</Tooltip>

							<Tooltip title="Print Email">
								<Button
									type="link"
									name="btn_print_email"
									icon={<FontAwesomeIcon icon={faPrint} />}
								></Button>
							</Tooltip>

							<Tooltip title="Move To Archived">
								<Button
									type="link"
									name="btn_active_archive"
									icon={<FontAwesomeIcon icon={faBoxArchive} />}
								></Button>
							</Tooltip>
						</Flex>
					</Col>
				</Row>
			</Form>
		</>
	);
}
