import { useEffect, useState } from "react";
import {
	Modal,
	Button,
	Empty,
	Tooltip,
	Row,
	Col,
	Flex,
	Form,
	Checkbox,
} from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { PDFDocument } from "pdf-lib";
import downloadjs from "downloadjs";

import { Container } from "./draggable_components/Container";
import { CustomDragLayer } from "./draggable_components/CustomDragLayer";
import moment from "moment";
import validateRules from "../../../../providers/validateRules";
import { useLocation } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ModalFilePreview(props) {
	const { toggleModalFilePreview, setToggleModalFilePreview } = props;

	const [form] = Form.useForm();
	const location = useLocation();
	const [numPages, setNumPages] = useState();
	const [signatureX, setSignatureX] = useState(360);
	const [signatureY, setSignatureY] = useState(-150);

	const onDocumentLoadSuccess = (e) => {
		setNumPages(e.numPages);
	};

	const downloadPdf = async () => {
		let pdf_file_name =
			toggleModalFilePreview.data.pdf_file_name ?? "epdffsuudsac.pdf";

		const url = toggleModalFilePreview.data.file_path;
		const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

		const pdfDoc = await PDFDocument.load(existingPdfBytes);

		const signatureUrl = toggleModalFilePreview.data.signature;
		const signatureImageBytes = await fetch(signatureUrl).then((res) =>
			res.arrayBuffer()
		);

		const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
		const signatureImageDims = signatureImage.scale(0.25);

		const pages = pdfDoc.getPages();
		const firstPage = pages[0];

		let signXPos = signatureX;
		let signYPos = Math.round(250 + signatureY);

		firstPage.drawImage(signatureImage, {
			x: signXPos,
			y: signYPos,
			width: signatureImageDims.width,
			height: signatureImageDims.height,
		});

		let dateCreated = `Date Created`;
		let textMoment = `${moment().format("MM/DD/YYYY")}`;

		firstPage.drawText(dateCreated, {
			x: signXPos + 120,
			y: signYPos + 20,
			size: 8,
		});
		firstPage.drawText(textMoment, {
			x: signXPos + 120,
			y: signYPos + 10,
			size: 8,
		});

		const pdfBytes = await pdfDoc.save();
		downloadjs(pdfBytes, pdf_file_name, "application/pdf");
	};

	const RenderPdf = (propsRender) => {
		const { item } = propsRender;
		const [pageNumber, setPageNumber] = useState(1);
		const [pdfHeight, setpdfHeight] = useState(0);

		useEffect(() => {
			if (item.pdf) {
				async function getPdfPropertyValue() {
					const url = item.pdf;
					const existingPdfBytes = await fetch(url).then((res) =>
						res.arrayBuffer()
					);
					const pdfDoc = await PDFDocument.load(existingPdfBytes);
					const pages = pdfDoc.getPages();
					const firstPage = pages[0];
					const { width, height } = firstPage.getSize();
					setpdfHeight(height);
				}

				getPdfPropertyValue();
			}

			return () => {};
		}, []);

		return (
			<>
				<Form form={form}>
					<DndProvider backend={HTML5Backend}>
						<Container
							height={pdfHeight}
							content={
								toggleModalFilePreview.data.signature && (
									<Flex gap={10} align="center">
										<img
											src={toggleModalFilePreview.data.signature}
											alt="pdf"
											style={{
												width: 120,
											}}
										/>
										<div
											style={{
												fontSize: 10,
												width: 60,
											}}
										>
											Date Created <br />
											{moment().format("MM/DD/YYYY")}
										</div>
									</Flex>
								)
							}
							setSignatureX={setSignatureX}
							setSignatureY={setSignatureY}
						>
							<Document file={item.pdf} onLoadSuccess={onDocumentLoadSuccess}>
								<Page pageNumber={1} renderTextLayer={false} />
							</Document>
						</Container>

						<CustomDragLayer />
					</DndProvider>

					{location.pathname.includes("received") && (
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Form.Item
								name="attach_signature"
								rules={[validateRules.required]}
								initialValue={false}
								valuePropName="checked"
							>
								<Flex justify="center">
									<Checkbox>Attach Signature</Checkbox>
								</Flex>
							</Form.Item>
						</Col>
					)}

					<Flex vertical justify="center">
						<div className="action">
							<Button
								onClick={() => setPageNumber((ps) => (ps > 1 ? ps - 1 : 1))}
								className={pageNumber > 1 ? "btn-main-primary" : ""}
							>
								PREV
							</Button>
							<Button
								onClick={() =>
									setPageNumber((ps) => (ps < numPages ? ps + 1 : numPages))
								}
								className={numPages !== pageNumber ? "btn-main-primary" : ""}
							>
								NEXT
							</Button>
						</div>
					</Flex>
				</Form>
			</>
		);
	};

	const PdfFileRender = () => {
		if (
			toggleModalFilePreview.data &&
			toggleModalFilePreview.data.attachments &&
			toggleModalFilePreview.data.attachments.length
		) {
			return (
				<>
					{toggleModalFilePreview.data.attachments.map((item, index) => {
						return (
							<div key={index}>
								<RenderPdf item={item} />
							</div>
						);
					})}

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: 12,
						}}
					>
						<div
							style={{
								width: "100%",
								textAlign: "center",
							}}
						>
							{/* <Button
								onClick={() => downloadPdf()}
								className="btn-main-primary"
							>
								DOWNLOAD
							</Button> */}
						</div>
					</div>
				</>
			);
		} else {
			return (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No File Pdf" />
			);
		}
	};

	return (
		<Modal
			title="File Preview"
			wrapClassName="modal-wrap-upload-file-preview"
			open={toggleModalFilePreview.open}
			onCancel={() => {
				setToggleModalFilePreview({
					open: false,
					data: null,
				});
			}}
			footer={null}
		>
			{/* <div
				style={{
					display: "flex",
					justifyContent: "center",
					gap: "10px",
					marginTop: "10px",
				}}
			>
				{pdfAttachments.map((item, index) => (
					<Tooltip title={item.file_name} key={index}>
						<Button
							onClick={() => setPdfAttachment(item.pdf)}
							className={pdfAttachment === item.pdf ? "active-file" : ""}
						>
							{index + 1}.{" "}
							{item.file_name.length > 10
								? item.file_name.substring(0, 10) + "..."
								: item.file_name}
						</Button>
					</Tooltip>
				))}
			</div> */}

			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24}>
					<PdfFileRender />
				</Col>
			</Row>
		</Modal>
	);
}
