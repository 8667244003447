import { Button, Form, Modal, notification, TreeSelect, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import validateRules from "../../../../providers/validateRules";
import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";
import dayjs from "dayjs";
import { apiUrl } from "../../../../providers/companyInfo";
import FloatTextArea from "../../../../providers/FloatTextArea";

const { SHOW_PARENT } = TreeSelect;

export default function ModalFileForward(props) {
	const {
		toggleModalFileForward,
		setToggleModalFileForward,
		dataTreeUser,
	} = props;

	const [form] = Form.useForm();
	const [emailAttachment, setEmailAttachment] = useState([]);
	const [selectedTreeData, setSelectedTreeData] = useState([]);

	const treeData = [
		{
			title: "All",
			value: "All",
			key: "All",
			disabled: selectedTreeData.includes("Department"),
		},
		{
			title: "Department",
			value: "Department",
			key: "Department",
			children: dataTreeUser
				? dataTreeUser
						.filter(
							(item) =>
								item.category === "Department" &&
								item.children &&
								item.children.length > 0
						)
						.sort((a, b) => a.value.localeCompare(b.value))
						.map((item) => ({
							...item,
							disabled: selectedTreeData.includes("All"),
						}))
				: [],
			disabled: selectedTreeData.includes("All"),
		},
		{
			title: "Individual",
			value: "Individual",
			key: "Individual",
			children: dataTreeUser
				? dataTreeUser.filter(
						(item) => item.title != "All" && item.category != "Department"
				  )
				: [],
		},
	];

	const {
		mutate: mutateForwardDocument,
		isLoading: isLoadingUpdateDocument,
	} = POST(`api/document_forwarded`, "document_forwarded_list", true, () => {});

	const onFinish = (values) => {
		let data = new FormData();
		let originData = toggleModalFileForward.data;

		let forwardToRecipients = [];
		let forwardToFilter = values.forwarded_to_id;

		if (forwardToFilter.find((x) => x === "All")) {
			if (dataTreeUser) {
				dataTreeUser
					.filter((x) => x.title === "All")
					.forEach((item) => {
						item.users.forEach((user) => {
							if (!forwardToRecipients.find((x) => x === user.id)) {
								forwardToRecipients.push(user.id);
							}
						});
					});
			}
		}

		if (forwardToFilter.find((x) => x === "Department")) {
			if (dataTreeUser) {
				dataTreeUser
					.filter((item) => item.category === "Department")
					.forEach((item) => {
						item.children.forEach((deptUser) => {
							if (!forwardToRecipients.find((x) => x === deptUser.id)) {
								forwardToRecipients.push(deptUser.id);
							}
						});
					});
			}
		}

		if (forwardToFilter.find((x) => x === "Individual")) {
			if (dataTreeUser) {
				dataTreeUser
					.filter((item) => item.category === "Individual")
					.forEach((item) => {
						forwardToRecipients.push(item.id);
					});
			}
		}

		if (
			forwardToFilter.filter(
				(x) => x !== "Individual" && x !== "Department" && x !== "All"
			)
		) {
			if (dataTreeUser) {
				let users = dataTreeUser.find((x) => x.title === "All");

				if (users) {
					users.users
						.filter(
							(x) =>
								forwardToFilter.includes(x.value) ||
								forwardToFilter.includes(x.department)
						)
						.forEach((user) => {
							let findExist = forwardToRecipients.find((x) => x === user.id);

							if (!findExist) {
								forwardToRecipients.push(user.id);
							}
						});
				}
			}
		}

		data.append(
			"forwarded_to_id",
			forwardToRecipients.join(",")
				? forwardToRecipients.join(",")
				: values.forwarded_to_id.map((item) => item.id).join(",")
		);

		console.log("forwardToRecipients: ", forwardToRecipients);
		data.append("note", values.note);
		data.append("document_id", originData.id);

		data.append(
			"id",
			toggleModalFileForward.data && toggleModalFileForward.data.id
				? toggleModalFileForward.data.id
				: ""
		);

		emailAttachment.forEach((item) => {
			if (item.originFileObj) {
				data.append("attachments[]", item.originFileObj);
			} else {
				data.append("attachments[]", item.id);
			}
		});

		mutateForwardDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalFileForward({
						open: false,
						data: null,
					});
					form.resetFields();

					notification.success({
						message: "ForwardDocument",
						description: res.message,
					});
					setSelectedTreeData([]);
				} else {
					notification.error({
						message: "ForwardDocument",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	// useEffect(() => {
	// 	console.log("toggleModalFileForward: ", toggleModalFileForward);

	// 	if (toggleModalFileForward.open) {
	// 		if (
	// 			toggleModalFileForward &&
	// 			toggleModalFileForward.data &&
	// 			toggleModalFileForward.data.id !== null
	// 		) {
	// 			let forwardTo = [];

	// 			if (
	// 				toggleModalFileForward.data &&
	// 				toggleModalFileForward.data.document_recipients &&
	// 				toggleModalFileForward.data.document_recipients.length > 0
	// 			) {
	// 				forwardTo = toggleModalFileForward.data.document_recipients
	// 					.filter((item) => item.profile !== null)
	// 					.map((item) => ({
	// 						value: item.profile.id,
	// 						label: item.profile.user.email,
	// 					}));
	// 			}

	// 			if (
	// 				toggleModalFileForward.data &&
	// 				toggleModalFileForward.data.attachments.length > 0
	// 			) {
	// 				let attachments = toggleModalFileForward.data.attachments.map(
	// 					(item) => {
	// 						return {
	// 							id: item.id,
	// 							uid: item.id,
	// 							name: item.file_name,
	// 							src: apiUrl(item.file_path),
	// 							url: apiUrl(item.file_path),
	// 							file_path: item.file_path, // File path for server reference
	// 							originFileObj: null, // Already uploaded files don’t need this
	// 						};
	// 					}
	// 				);

	// 				setEmailAttachment(attachments);
	// 			}

	// 			// Set initial form values
	// 			form.setFieldsValue({
	// 				...toggleModalFileForward.data,
	// 				date_completion: toggleModalFileForward.data.date_completion
	// 					? dayjs(toggleModalFileForward.data.date_completion)
	// 					: null,
	// 				forwarded_to_id: forwardTo,
	// 			});
	// 		}
	// 	}

	// 	return () => {};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [toggleModalFileForward]);

	const onChange = (newValue) => {
		setSelectedTreeData(newValue);
	};

	return (
		<Modal
			title="Form Modal Forward File"
			open={toggleModalFileForward.open}
			onCancel={() => {
				setToggleModalFileForward({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalFileForward({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={(values) => form.submit(values)}
					// loading={isLoadingUpdateDocument}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Row gutter={[12, 0]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="forwarded_to_id" rules={[validateRules.required]}>
							<TreeSelect
								value={selectedTreeData}
								onChange={onChange}
								treeData={treeData}
								treeCheckable
								treeLine
								showCheckedStrategy={SHOW_PARENT}
								placeholder="Forward To"
								popupMatchSelectWidth
								allowClear
								notFoundContent="No user found"
								required
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="note" rules={[validateRules.required]}>
							<FloatTextArea label="Note" placeholder="Note" allowClear />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
