import { Routes, Route } from "react-router-dom";
import {
	faHome,
	faUsers,
	faEnvelopes,
	faPaperPlane,
	faInboxIn,
	faCog,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import PageRequestPermission from "../views/errors/PageRequestPermission";
import Page404 from "../views/errors/Page404";

import PageLogin from "../views/public/PageLogin/PageLogin";

import PageDashboard from "../views/private/PageDashboard/PageDashboard";

import PageTest from "../views/private/PageTest";
import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";
import PageReport from "../views/private/PageReport/PageRecipient";
import PageFailedSentEmails from "../views/private/PageFailedSentEmails/PageFailedSentEmails";
import PageDepartment from "../views/private/PageSystemSettings/PageDepartment/PageDepartment";
import PageDocument from "../views/private/PageDocument/PageDocument";
import PageMail from "../views/private/PageMail/PageMail";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			{/* start document */}
			<Route
				path="/mailbox/all"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="All"
						subtitle="ADMIN"
						pageId="PageDocument"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Mailbox",
							},
							{
								name: "All",
							},
						]}
						component={PageDocument}
					/>
				}
			/>

			<Route
				path="/mailbox/received" // INBOX
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="Received"
						subtitle="ADMIN"
						pageId="PageDocument"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Mailbox",
							},
							{
								name: "Received",
							},
						]}
						component={PageDocument}
					/>
				}
			/>

			<Route
				path="/mailbox/sent"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="Sent"
						subtitle="ADMIN"
						pageId="PageDocument"
						pageHeaderIcon={faPaperPlane}
						breadcrumb={[
							{
								name: "Mailbox",
							},
							{
								name: "Sent",
							},
						]}
						component={PageDocument}
					/>
				}
			/>

			<Route
				path="/mailbox/forwarded"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="Forwarded"
						subtitle="ADMIN"
						pageId="PageDocument"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Mailbox",
							},
							{
								name: "Forwarded",
							},
						]}
						component={PageDocument}
					/>
				}
			/>

			<Route
				path="/mailbox/draft"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="Draft"
						subtitle="ADMIN"
						pageId="PageDocument"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Mailbox",
							},
							{
								name: "Draft",
							},
						]}
						component={PageDocument}
					/>
				}
			/>

			<Route
				path="/mailbox/starred"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="Starred"
						subtitle="ADMIN"
						pageId="PageDocument"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Mailbox",
							},
							{
								name: "Starred",
							},
						]}
						component={PageDocument}
					/>
				}
			/>
			{/* end document */}

			{/* start MAIL UI */}
			<Route
				path="/mailbox"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Mailbox"
						title="All"
						subtitle="ADMIN"
						pageId="PageMail"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Mailbox",
							},
						]}
						component={PageMail}
					/>
				}
			/>
			{/* end MAIL UI */}

			<Route
				path="/report"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Report"
						title="Report"
						subtitle="ADMIN"
						pageId="PageReport"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Report",
							},
						]}
						component={PageReport}
					/>
				}
			/>

			{/* Setting */}
			<Route
				path="/system-settings/department"
				element={
					<PrivateRoute
						// moduleCode="M-04"
						moduleName="System Settings - Department"
						title="Settings"
						subtitle="DEPARTMENT"
						pageId="PageDepartment"
						pageHeaderIcon={faCog}
						breadcrumb={[
							{
								name: "System Settings",
							},
							{
								name: "Department",
							},
						]}
						component={PageDepartment}
					/>
				}
			/>

			<Route
				path="/failed-sent-emails"
				element={
					<PrivateRoute
						// moduleCode="M-04"
						moduleName="Failed Sent Emails"
						title="Sent Emails"
						subtitle="FAILED"
						pageId="PageFailedSentEmails"
						pageHeaderIcon={faCog}
						breadcrumb={[
							{
								name: "Failed Sent Emails",
							},
						]}
						component={PageFailedSentEmails}
					/>
				}
			/>

			{/* VOTING */}
			<Route
				path="/test"
				element={
					<PageTest
						moduleCode="M-12"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faEnvelopes}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
					/>
				}
			/>

			<Route path="/request-permission" element={<PageRequestPermission />} />
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
