import React, { useEffect, useState } from "react";
import {
	Badge,
	Button,
	Col,
	Divider,
	Flex,
	notification,
	Popconfirm,
	Row,
	Select,
	Table,
	Tag,
	Tooltip,
} from "antd";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faFileSignature,
	faPaperPlane,
	faPencil,
	faPlus,
	faShare,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { GET, POST } from "../../../providers/useAxiosQuery";
import ModalFormCompose from "./components/ModalFormCompose";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../providers/CustomTableFilter";
import QuillOutputCollapse from "../../../providers/QuillOutputCollapse";
import notificationErrors from "../../../providers/notificationErrors";
import ModalFilePreview from "./components/ModalFilePreview";
import ModalFileForward from "./components/ModalFileForward";
import ModalFileReceived from "./components/ModalFileReceived";
import { apiUrl } from "../../../providers/companyInfo";

export default function PageDocument() {
	const location = useLocation();
	dayjs.extend(relativeTime);

	const [dataTreeUser, setDataTreeUser] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [expandedRows, setExpandedRows] = useState({});
	const [isBold, setIsBold] = useState(true); // Bold unread documents

	const [toggleModalFormCompose, setToggleModalFormCompose] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFilePreview, setToggleModalFilePreview] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFileReceived, setToggleModalFileReceived] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFileForward, setToggleModalFileForward] = useState({
		open: false,
		data: null,
	});
	const toggleShowAll = (recordId) => {
		setExpandedRows((prev) => ({
			...prev,
			[recordId]: !prev[recordId],
		}));
	};

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 10,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		from: location.pathname,
		department_id: "",
		isTrash: 0,
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			from: location.pathname,
			department_id: "",
			status: ["Active"],
			isTrash: 0,
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	GET(
		`api/tree_data_users`,
		"tree_data_users_dropdown",
		(res) => {
			// console.log("tree_data_users res", res);
			setDataTreeUser(res.data);
		},
		false
	);

	const { data: dataDocument, refetch: refetchSourceDocument } = GET(
		`api/documents?${new URLSearchParams(tableFilter)}`,
		"documents_received_list"
	);

	useEffect(() => {
		refetchSourceDocument();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const {
		mutate: mutateDeleteRefDocument,
		isLoading: isLoadingDocument,
	} = POST(`api/document_archived`, "documents_received_list");

	const handleSelectedArchived = () => {
		let data = { isTrash: tableFilter.isTrash, ids: selectedRowKeys };

		mutateDeleteRefDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Email",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Email",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper">
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						type="primary"
						icon={<FontAwesomeIcon icon={faPencil} />}
						name="btn_add"
						onClick={() =>
							setToggleModalFormCompose({
								open: true,
								data: null,
							})
						}
					>
						Compose Mail
					</Button>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Flex justify="space-between" className="tbl-top-filter">
						<Flex gap={10} className="flex-extra-filter">
							<Button
								type={tableFilter.isTrash === 0 ? "primary" : ""}
								className={`min-w-150 ${
									tableFilter.isTrash === 0 ? "active" : ""
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 0,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Active
							</Button>

							<Button
								type={tableFilter.isTrash === 1 ? "primary" : ""}
								className={`min-w-150 ${
									tableFilter.isTrash === 1 ? "active" : ""
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 1,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Archived
							</Button>

							{selectedRowKeys.length > 0 && (
								<Popconfirm
									title={
										<>
											Are you sure you want to
											<br />
											{tableFilter.isTrash === 0 ? "archive" : "restore"} the
											selected {selectedRowKeys.length > 1 ? "emails" : "email"}
											?
										</>
									}
									okText="Yes"
									cancelText="No"
									onConfirm={() => {
										handleSelectedArchived();
									}}
									disabled={isLoadingDocument}
								>
									<Button
										name="btn_active_archive"
										loading={isLoadingDocument}
										danger={tableFilter.isTrash === 0}
										type="primary"
										className={tableFilter.isTrash === 1 ? "btn-success" : ""}
									>
										{tableFilter.isTrash === 0 ? "ARCHIVE" : "RESTORE"} SELECTED
									</Button>
								</Popconfirm>
							)}

							<Select
								placeholder="Department"
								style={{ width: 300 }}
								allowClear
								showSearch
								filterOption={(input, option) => {
									// console.log("option", option);
									return (
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}
								onChange={(value) =>
									setTableFilter((ps) => ({
										...ps,
										department_id: value ? value : "",
									}))
								}
								options={
									dataTreeUser && dataTreeUser
										? dataTreeUser
												.filter((item) => item.category == "Department")
												.map((item) => ({
													label: item.title,
													value: item.id,
												}))
										: []
								}
							/>

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>

						<Flex gap={10}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</Flex>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24}>
							<Table
								id="tblApplicant"
								className="ant-table-default ant-table-striped table-vertical-top"
								dataSource={dataDocument && dataDocument.data.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky={{
									offsetHeader: 0,
								}}
								rowSelection={{
									selectedRowKeys,
									onChange: (selectedRowKeys) => {
										setSelectedRowKeys(selectedRowKeys);
									},
								}}
							>
								<Table.Column
									title="Action"
									key="action"
									dataIndex="action"
									align="center"
									width={200}
									render={(text, record) => {
										return (
											<>
												{location.pathname.includes("sent") && (
													<Tooltip title="Edit">
														<Button
															type="link"
															onClick={() =>
																setToggleModalFormCompose({
																	open: true,
																	data: record,
																})
															}
														>
															<FontAwesomeIcon icon={faPencil} />
														</Button>
													</Tooltip>
												)}

												<Tooltip title="View Attached File">
													<Button
														type="link"
														onClick={() => {
															setToggleModalFilePreview({
																open: true,
																data: record,
															});
															setIsBold(false);
														}}
													>
														<FontAwesomeIcon icon={faEye} />
													</Button>
												</Tooltip>

												{location.pathname.includes("sent") && (
													<Tooltip title="Re-send">
														<Button
															type="link"
															onClick={() =>
																setToggleModalFilePreview({
																	open: false,
																	data: record,
																})
															}
														>
															<FontAwesomeIcon icon={faPaperPlane} />
														</Button>
													</Tooltip>
												)}

												{location.pathname.includes("received") && (
													<>
														<Tooltip title="Sign">
															<Button
																type="link"
																className="color-1 primary-color w-auto h-auto p-10"
																onClick={() =>
																	setToggleModalFileReceived({
																		open: true,
																		data: record,
																	})
																}
															>
																<FontAwesomeIcon icon={faFileSignature} />
															</Button>
														</Tooltip>

														<Tooltip title="Forward">
															<Button
																type="link"
																className="color-1 primary-color w-auto h-auto p-10"
																onClick={() =>
																	setToggleModalFileForward({
																		open: true,
																		data: record,
																	})
																}
															>
																<FontAwesomeIcon icon={faShare} />
															</Button>
														</Tooltip>
													</>
												)}
											</>
										);
									}}
								/>

								<Table.Column
									title="Subject"
									key="subject"
									dataIndex="subject"
									sorter={(a, b) => a.subject.localeCompare(b.subject)}
									render={(_, record) => {
										let subject = record.subject;

										return location.pathname.includes("received") && isBold ? (
											<div style={{ fontWeight: isBold ? "bold" : "normal" }}>
												<QuillOutputCollapse quillOutput={subject} />
											</div>
										) : (
											<QuillOutputCollapse quillOutput={subject} />
										);
									}}
									width={200}
								/>

								<Table.Column
									title="Email Body"
									key="email_body"
									dataIndex="email_body"
									sorter={(a, b) => a.email_body.localeCompare(b.email_body)}
									render={(_, record) => {
										let email_body = record.email_body;

										return <QuillOutputCollapse quillOutput={email_body} />;
									}}
									width={200}
								/>

								{location.pathname.includes("sent") && (
									<>
										<Table.Column
											title="Date Sent"
											key="created_at"
											dataIndex="created_at"
											sorter={(a, b) =>
												dayjs(a.created_at).unix() - dayjs(b.created_at).unix()
											}
											render={(text, record) => {
												return (
													<>
														{dayjs(record.created_at).format("MMMM DD, YYYY")}
													</>
												);
											}}
											width={200}
										/>
									</>
								)}

								{location.pathname.includes("received") ||
								location.pathname.includes("forwarded") ? (
									<>
										<Table.Column
											title="From"
											key="from_id"
											dataIndex="from_id"
											width={200}
											sorter={(a, b) => {
												const fromA = a.from_id || "";
												const fromB = b.from_id || "";
												return fromA.localeCompare(fromB);
											}}
											render={(text, record) => {
												return (
													<>
														<Tag style={{ fontSize: "14px" }}>
															{record.from_fullname}
														</Tag>
													</>
												);
											}}
										/>
									</>
								) : null}

								{location.pathname.includes("sent") && (
									<>
										<Table.Column
											title="Recipient"
											key="recipient_id"
											dataIndex="recipient_id"
											width={250}
											render={(text, record) => {
												const showAll = expandedRows[record.id] || false;
												let recipients = [];

												if (
													record &&
													record.document_recipients &&
													record.document_recipients.length > 0
												) {
													recipients = record.document_recipients.map(
														(item) => ({
															email: item.profile?.user?.email,
															date_received: item.date_received,
														})
													);
												}

												const receivedCount = record.document_recipients.filter(
													(item) => item.date_received !== null
												).length;

												const displayedRecipients = showAll
													? recipients
													: recipients.slice(0, 3);

												return (
													<>
														{receivedCount === 0 ? (
															<Tag color="blue" style={{ fontSize: "14px" }}>
																Pending{" "}
																{record.document_recipients.length -
																	receivedCount}
																/{record.document_recipients.length}
															</Tag>
														) : receivedCount ===
														  record.document_recipients.length ? (
															<Tag color="green" style={{ fontSize: "14px" }}>
																Received
															</Tag>
														) : (
															<Tag color="blue" style={{ fontSize: "14px" }}>
																Pending{" "}
																{record.document_recipients.length -
																	receivedCount}
																/{record.document_recipients.length}
															</Tag>
														)}

														{displayedRecipients.map((recipient, index) => (
															<Badge
																status={
																	recipient.date_received
																		? "success"
																		: "default"
																}
																key={index}
																text={<>{recipient.email}</>}
															/>
														))}
														{recipients.length > 3 && (
															<Button
																type="link"
																onClick={() => toggleShowAll(record.id)}
															>
																{showAll ? "Show Less" : "Show More"}
															</Button>
														)}
													</>
												);
											}}
										/>
									</>
								)}

								<Table.Column
									title="Note"
									key="notes"
									dataIndex="notes"
									width={300}
									render={(text, record) => {
										const showAll = expandedRows[record.id] || false;
										let recipients = [];
										let notes = [];

										if (
											record &&
											record.document_recipients &&
											record.document_recipients.length > 0
										) {
											const filteredRecipients = record.document_recipients.filter(
												(item) => item.notes !== null
											);

											recipients = filteredRecipients.map((item) => ({
												email: item.profile?.user?.email.split("@")[0],
												updated_at: dayjs(item.updated_at).format(
													"MMMM DD, YYYY hh:mm A"
												),
											}));

											notes = filteredRecipients.map((item) => item.notes);
										}

										const displayedRecipients = showAll
											? recipients
											: recipients.slice(0, 3);

										return (
											<>
												{displayedRecipients.map((recipient, index) => (
													<>
														{dayjs(recipient.updated_at).format(
															"MMMM DD, YYYY hh:mm A"
														)}
														<br />
														<strong>
															{recipient.email.split("@")[0]}
														</strong>: {notes[index] || ""}
														<Divider style={{ margin: "5px 0px 5px 0px" }} />
													</>
												))}
												{recipients.length > 3 && (
													<Button
														type="link"
														onClick={() => toggleShowAll(record.id)}
													>
														{showAll ? "Show Less" : "Show More"}
													</Button>
												)}
											</>
										);
									}}
								/>

								<Table.Column
									title="Signed By"
									key="signed_by_id"
									dataIndex="signed_by_id"
									sorter={(a, b) => a.title.localeCompare(b.title)}
									width={300}
									render={(text, record) => {
										const showAll = expandedRows[record.id] || false;
										let recipients = [];

										if (
											record &&
											record.document_recipients &&
											record.document_recipients.length > 0
										) {
											const filteredRecipients = record.document_recipients.filter(
												(item) => item.date_signed !== null
											);

											recipients = filteredRecipients.map((item) => ({
												email: item.profile?.user?.email,
												date_signed: item.date_signed,
											}));
										}

										const displayedRecipients = showAll
											? recipients
											: recipients.slice(0, 2);

										return (
											<>
												{displayedRecipients.map((recipient, index) => (
													<Badge
														status={
															recipient.date_signed ? "success" : "default"
														}
														key={index}
														text={
															<>
																{recipient.email}
																<br />
																{dayjs(recipient.updated_at).format(
																	"MMMM DD, YYYY hh:mm A"
																)}
																<Divider
																	style={{ margin: "5px 0px 5px 0px" }}
																/>
															</>
														}
													/>
												))}
												{recipients.length > 2 && (
													<Button
														type="link"
														onClick={() => toggleShowAll(record.id)}
													>
														{showAll ? "Show Less" : "Show More"}
													</Button>
												)}
											</>
										);
									}}
								/>

								<Table.Column
									title="Date Completion"
									key="date_completion"
									dataIndex="date_completion"
									sorter={(a, b) =>
										dayjs(a.created_at).unix() - dayjs(b.created_at).unix()
									}
									render={(text, record) => {
										return (
											<>
												{record.date_completion
													? `${dayjs(record.date_completion).format(
															"MMMM DD, YYYY"
													  )}`
													: ""}
											</>
										);
									}}
									width={200}
								/>
							</Table>
						</Col>

						<Col xs={24} sm={24} md={24}>
							<div className="tbl-bottom-filter">
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									setPaginationTotal={dataDocument && dataDocument?.data?.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper"
								/>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>

			<ModalFormCompose
				toggleModalFormCompose={toggleModalFormCompose}
				setToggleModalFormCompose={setToggleModalFormCompose}
				dataTreeUser={dataTreeUser}
			/>

			<ModalFileReceived
				toggleModalFileReceived={toggleModalFileReceived}
				setToggleModalFileReceived={setToggleModalFileReceived}
				dataTreeUser={dataTreeUser}
			/>

			<ModalFileForward
				toggleModalFileForward={toggleModalFileForward}
				setToggleModalFileForward={setToggleModalFileForward}
				dataTreeUser={dataTreeUser}
			/>

			<ModalFilePreview
				toggleModalFilePreview={toggleModalFilePreview}
				setToggleModalFilePreview={setToggleModalFilePreview}
			/>
		</>
	);
}
