import { useEffect, useState } from "react";
import {
	Button,
	Empty,
	Flex,
	Popconfirm,
	Typography,
	notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import ESignaturePad from "signature_pad";

import { POST } from "../../../../providers/useAxiosQuery";
import { apiUrl, userData } from "../../../../providers/companyInfo";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalUploadSignature from "./ModalUploadSignature";
import dataURLtoBlob from "../../../../providers/dataURLtoBlob";

export default function SignaturePad(props) {
	const { fileSignature, setFileSignature } = props;

	const [signatureValue, setSignatureValue] = useState(null);
	const [checkEmptySignature, setCheckEmptySignature] = useState(false);
	const [toggleModalUploadSignature, setToggleModalUploadSignature] = useState(
		false
	);
	const [undoStack, setUndoStack] = useState([]);
	const [redoStack, setRedoStack] = useState([]);

	const {
		mutate: mutateUploadSignature,
		loading: loadingUploadSignature,
	} = POST(`api/upload_signature`, "upload_signature");

	const handleSave = () => {
		let data = new FormData();

		if (fileSignature.file) {
			data.append("signature", fileSignature.file);
		} else {
			data.append(
				"signature",
				dataURLtoBlob(signatureValue.toDataURL()),
				"signature.png"
			);
		}

		data.append("user_id", userData().id);

		mutateUploadSignature(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalUploadSignature(false);
					notification.success({
						message: "Upload Signature",
						description: res.message,
					});
					let signature = res.data.attachments.filter(
						(f) => f.file_description === "Signature"
					);
					setFileSignature({
						file: null,
						src: null,
						filePath: apiUrl(signature[0].file_path),
						fileName: null,
					});
					signatureValue.clear();
					setCheckEmptySignature(true);
				} else {
					notification.error({
						message: "Upload Signature",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const handleClear = () => {
		setUndoStack([]);
		setRedoStack([]);
		signatureValue.clear();
	};

	const handleUndo = () => {
		const data = signatureValue.toData();
		if (data.length) {
			setRedoStack([...redoStack, data.pop()]);
			signatureValue.fromData(data);
			setUndoStack(data);
		}
	};

	const handleRedo = () => {
		if (redoStack.length) {
			const data = [...undoStack, redoStack.pop()];
			signatureValue.fromData(data);
			setUndoStack(data);
		}
	};

	useEffect(() => {
		const canvas = document.getElementById("signature-pad");

		const signaturePad = new ESignaturePad(canvas);
		setSignatureValue(signaturePad);

		function resizeCanvas() {
			const ratio = Math.max(window.devicePixelRatio || 1, 1);
			canvas.width = canvas.offsetWidth * ratio;
			canvas.height = canvas.offsetHeight * ratio;
			canvas.getContext("2d").scale(ratio, ratio);
			signaturePad.clear();
		}

		window.addEventListener("resize", resizeCanvas);
		resizeCanvas();

		if (signaturePad.isEmpty()) {
			setCheckEmptySignature(true);
		}

		return () => {
			window.removeEventListener("resize", resizeCanvas);
		};
	}, []);

	return (
		<div className="signature-pad-wrapper">
			{!fileSignature.filePath ? (
				<div className="empty-wrapper">
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description="No Signature"
					>
						<>
							<Button
								icon={<FontAwesomeIcon icon={faEdit} />}
								onClick={() => setCheckEmptySignature(false)}
							>
								Create Signature
							</Button>

							<Button
								icon={<FontAwesomeIcon icon={faEdit} />}
								onClick={() => setToggleModalUploadSignature(true)}
							>
								Upload Signature
							</Button>
						</>
					</Empty>
				</div>
			) : (
				<>
					<img
						src={fileSignature.filePath}
						alt="fileSignature"
						className="signature-image"
					/>

					<div className="action">
						<Button
							icon={<FontAwesomeIcon icon={faEdit} />}
							onClick={() => setCheckEmptySignature(false)}
						>
							Update Signature
						</Button>

						<Button
							icon={<FontAwesomeIcon icon={faEdit} />}
							onClick={() => setToggleModalUploadSignature(true)}
						>
							Upload Signature
						</Button>
					</div>
				</>
			)}

			<canvas
				id="signature-pad"
				style={{
					display: checkEmptySignature ? "none" : "block",
				}}
			/>

			{!checkEmptySignature && (
				<>
					<Typography.Text disabled>Sign Above</Typography.Text>
					<br />

					<Flex gap={10}>
						<Button
							className="primary clear"
							data-action="clear"
							onClick={handleClear}
						>
							Clear
						</Button>

						<Button
							className="button undo"
							data-action="undo"
							onClick={handleUndo}
						>
							Undo
						</Button>

						<Button
							className="button redo"
							data-action="redo"
							onClick={handleRedo}
						>
							Redo
						</Button>
					</Flex>
					<br />

					<Popconfirm
						title="Are you sure to save this signature?"
						onConfirm={() => {
							handleSave();
						}}
						onCancel={() => {
							notification.error({
								message: "Upload Signature",
								description: "Signature not saved!",
							});
						}}
						okText="Yes"
						cancelText="No"
						placement="topRight"
						okButtonProps={{
							className: "btn-main-invert",
						}}
					>
						<Button
							type="primary"
							style={{
								marginBottom: "10px",
							}}
						>
							Save
						</Button>
					</Popconfirm>
				</>
			)}

			<ModalUploadSignature
				toggleModalUploadSignature={toggleModalUploadSignature}
				setToggleModalUploadSignature={setToggleModalUploadSignature}
				handleSave={handleSave}
				fileSignature={fileSignature}
				setFileSignature={setFileSignature}
				loadingUploadSignature={loadingUploadSignature}
			/>
		</div>
	);
}
