import { Row, Col, Collapse, Card, Table, Flex } from "antd";
import { useQueryClient } from "react-query";

import Highcharts from "highcharts";
import highchartsSetOptions from "../../../providers/highchartsSetOptions";
require("highcharts/modules/accessibility")(Highcharts);
// require("highcharts/modules/no-data-to-display")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard() {
	const queryClient = useQueryClient();
	highchartsSetOptions(Highcharts);

	return (
		<>
			<Flex horizontal justify="space-between">
				<Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14} className="left">
					<Col span={8}>
						<Card title="Filed Documents" bordered={false} hoverable>
							Under Development
						</Card>
					</Col>
					<Col span={8}>
						<Card title="Pending" bordered={false} hoverable>
							Under Development
						</Card>
					</Col>
					<Col span={8}>
						<Card title="Under Development" bordered={false} hoverable>
							Under Development
						</Card>
					</Col>
				</Col>

				<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="right">
					Under Development
				</Col>
			</Flex>
		</>
	);
}
